import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import classnames from "classnames"
import { useNavigate } from "react-router-dom";
import "./DynamicFormComponent.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "pages/JobPages/JobList/JobListCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button,
} from "reactstrap"
import { useDropzone } from "react-dropzone"
// import DayWiseItinerary from "./DayWiseItinerary "
// import AccessibilityForm from "./AccessibilityOptions"
// import HealthRestrictionsForm from "./HealthRestrictions"
// import ItineraryDayComponent from "./ItineraryDayComponent "
import ImageUploadComponent from "./ImageUploadComponent"
//import ImageUploader from "./StyledDropzone"
import MyMultiSelectComponent from "./MyMultiselectComponent"
import MyMultiSelectPointAComponent from "./MyMultiselectPointAComponent"
import MyMultiSelectPointBComponent from "./MyMultiselectPointBComponent"
// import Inclusions from "./Inclusions"
// import MyEditor from "./EditorForm"
// import InclusionExclusionForm from "./InclusionAndExclusion"
// import GuideLanguagesForm from "./GuideAndLanguages"
// import { JobNo } from "pages/JobPages/JobList/JobListCol"

function CreateTour() {
  const navigate = useNavigate(); // Initialize navigate
  //meta title
  document.title = "Create a Package | Flymiles"
  const [data, setData] = useState([])
  const [warning, setWarning] = useState("")
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [countries, setCountries] = useState(null)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [productId, setProductId] = useState(null)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [productName, setProductName] = useState("")
  const [productCode, setProductCode] = useState("")
  const [countryId, setCountryId] = useState("")
  const [stateId, setStateId] = useState("")
  const [cityId, setCityId] = useState("")
  const [vehicleId, setVehicleId] = useState("")
  const [AddressType, setAddressType] = useState("")

  const [Meet, setMeet] = useState("")
  const [Water, setWater] = useState("")
  const [RoutePrice, setRoutePrice] = useState("")
  const [newvehicleid, setnewvehicleid] = useState("")
  const [newrouteid, setnewrouteid] = useState("")
  const [duration, setDuration] = useState("")
  const [luggage, setlugg] = useState("")
  const [description, setProductDes] = useState("")

  const [tourType, setTourType] = useState("")
  const [cities, setCities] = useState([])
  const [inclusions, setInclusions] = useState([])
  const [image, setImage] = useState([])
  const [multiImages, setMultiImages] = useState([])
  const [accessibilityQuestions, setAccessibilityQuestions] = useState([])
  const [healthRestrictions, setHealthRestrictions] = useState([])
  const [IncExc, setIncExc] = useState([])
  const [lang, setLang] = useState([])
  const [itinerary, setItinerary] = useState([])
  const [selectProduct, setselectProduct] = useState("")
  const [noPax, setNoPax] = useState(0)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [confirmationType, setConfirmationType] = useState("")
  const [cutOffTime, setcutOffTime] = useState("")
  const [priceChild, setPriceChild] = useState("")
  const [priceAdult, setPriceAdult] = useState("")
  const [priceInfant, setPriceInfant] = useState("")
  const [productListing, setProductListing] = useState("")
  const [publishType, setPublishType] = useState("")
  const [promotionDiscountPrice, setPromotionDiscountPrice] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [fetchImg, setFetchImg] = useState(false)
  const [statesies, setStates] = useState([])
  const [citiess, setCitiess] = useState([])
  const [viewtoaddress, setViewToAddress] = useState([]);
  const [viewfromaddress, setViewFromAddress] = useState([]);
  const [pointA, setPointA] = useState([]);
  const [pointB, setPointB] = useState([]);
  const [routeDetails, setrouteDetails] = useState([]);
  const [viewvehicle, setVehicles] = useState([]);
  const [Pickupinfo, setPickupinfo] = useState("");
  const [formData, setFormData] = useState([
    {
      vehicleId: "",
      tourType: "",
      addressType: "A To B",
      fromAddress: "",
      toAddress: "",
      routePrice: "",
      meet: "",
      water: "",
    },
    {
      vehicleId: "",
      tourType: "",
      addressType: "B To A",
      fromAddress: "", // Prepopulated dynamically
      toAddress: "", // Prepopulated dynamically
      routePrice: "",
      meet: "",
      water: "",
    },
  ]);
  
  // Handler to add more forms
  const addMore = () => {
    setFormData([
      ...formData,
      {
        vehicleId: "",
        tourType: "",
        addressType: "A To B",
        fromAddress: "",
        toAddress: "",
        routePrice: "",
        meet: "",
        water: "",
      },
      {
        vehicleId: "",
        tourType: "",
        addressType: "B To A",
        fromAddress: "", // Prepopulated dynamically
        toAddress: "", // Prepopulated dynamically
        routePrice: "",
        meet: "",
        water: "",
      },
    ]);
  };
  
  const handlerouteChange = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
  
    // Automatically update 'fromAddress' and 'toAddress' for the second entry (B to A)
    if (field === "fromAddress" && index % 2 === 0 && index + 1 < formData.length) {
      updatedFormData[index + 1].toAddress = value; // Reverse for second entry
    }
    if (field === "toAddress" && index % 2 === 0 && index + 1 < formData.length) {
      updatedFormData[index + 1].fromAddress = value; // Reverse for second entry
    }
  
    // Sync 'tourType' and 'vehicleId' for B to A when A to B is updated
    if (field === "tourType" && index % 2 === 0 && index + 1 < formData.length) {
      updatedFormData[index + 1].tourType = value;
    }
    if (field === "vehicleId" && index % 2 === 0 && index + 1 < formData.length) {
      updatedFormData[index + 1].vehicleId = value;
    }
  
    setFormData(updatedFormData);
  };


  const handlepointChange = (selectedCities, point) => {
    if (point === "pointA") {
      setPointA(selectedCities);
      setrouteDetails(prevRouteDetails => [
        ...selectedCities.map(city => ({
          type: "pickup",
          address: city.label,
        })),
        ...pointB.map(city => ({
          type: "destination",
          address: city.label,
        })),
      ]);
    } else if (point === "pointB") {
      setPointB(selectedCities);
      setrouteDetails(prevRouteDetails => [
        ...pointA.map(city => ({
          type: "pickup",
          address: city.label,
        })),
        ...selectedCities.map(city => ({
          type: "destination",
          address: city.label,
        })),
      ]);
    }
  };
  
  useEffect(() => {
    console.log(routeDetails);
  }, [routeDetails]);
 








  const handleCityChange = selectedCities => {
    console.log(selectedCities)
    // Assuming you want to store just the values of selected options
    const cities = selectedCities.map(city => city.value)
    setCities(cities)
  }
  const handleInclusionsChange = inclusions => {
    setInclusions(inclusions)
    // console.log("incl", inclusions)
  }
  const handleAcc = Acc => {
    setAccessibilityQuestions(Acc)
    // console.log("incl", inclusions)
  }
  const handleHR = HR => {
    setHealthRestrictions(HR)
    // console.log("incl", inclusions)
  }
  const handleIncExc = IE => {
    setIncExc(IE)
  }
  const handleLang = lang => {
    setLang(lang)
    // console.log("incl", lang)
  }
  const handleDay = day => {
    setItinerary(day)
    // console.log("incl", inclusions)
  }
  const handleMainImage = image => {
    // console.log("i2", image)
    setImage(image)
  }
  const handleImage = image => {
    // console.log("i", image)
    setMultiImages(image)
  }

  const submitImages = async () => {
    // e.preventDefault()
    setIsLoading(true)
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/transafer/add_images.php"

    const payload = {
      vehicle_id: newvehicleid,
      images: image,
      // images: multiImages,
    }

    console.log(payload)

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      if (data) {
        setIsLoading(false)
        setFetchImg(false)
        
        navigate("/allvehicle");
        alert(data.success);
      
      }
      // Handle success response
    } catch (error) {
      console.error("Error submitting images:", error)
      setIsLoading(false)
      setFetchImg(false)

      // Handle errors here
    }
  }
  const handleSubmit = async e => {
 
      console.log(routeDetails);

    setIsLoading(true)

   
    const updateFormData = {

      route_name: productName?.target?.value,
      country_id: countryId,
      state_id: stateId,
      city_id: cityId,
      transfer_type: tourType,
      route_details: routeDetails,
    }
    console.log(updateFormData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/create_route.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFormData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Success:", result);
      if(result.error){
        setIsLoading(false)
        toggleTabVertical(activeTabVartical)
      }else{
        setnewrouteid(result.route_id);
        console.log(newrouteid);
       
        setIsLoading(false)
        toggleTabVertical(activeTabVartical + 1)
      }
    

    } catch (error) {
      setIsLoading(false)
      console.error("Error during API call:", error);
     
      alert("Failed Please try again.");
    }

    
  }
  useEffect(() => {
    if (newrouteid) {
      fetchFromAddress();
      fetchToAddress();
    }
  }, [newrouteid]);


  const handlerouteSubmit = async () => {
    console.log(formData); // Log all forms data
    setIsLoading(true)

   
    const updateFormData = {

      route_id: newrouteid,
      pickup_instructions: Pickupinfo,
      price_detail: formData.map(item => ({
        vehicle_id: item.vehicleId,
        address_type: item.addressType,
        from_add: item.fromAddress,
        to_add: item.toAddress,
        price: item.routePrice,
        meet_greet: item.meet,
        trasnfer_type: item.tourType,
        water_bottle: item.water,
      })),
      
    }
    console.log(updateFormData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/add_pricing_route.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFormData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Success:", result);
      navigate("/allroutes");
      setIsLoading(false)
      toggleTabVertical(activeTabVartical + 1)
    


    } catch (error) {
      setIsLoading(false)
      console.error("Error during API call:", error);
     
      alert("Failed Please try again.");
    }

  };


  const submitAccess = async () => {
    // Prepare the data in the required API format
    const accessibilityData = {
      prodcut_id: productId, // Ensure this ID is dynamically set based on your needs
      accessibility: accessibilityQuestions.map(q => ({
        title: q.question,
        yes_no: q.answer,
      })),
    }

    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/add_accessibility.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any required headers here
          },
          body: JSON.stringify(accessibilityData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      console.log("Success:", result)
      // Handle success response
    } catch (error) {
      console.error("Error during API call:", error)
      // Handle errors
    }
  }
  const submitHealthRes = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_health_restriction.php"
    const payload = {
      prodcut_id: productId, // Assuming product ID is static; replace with dynamic value if necessary
      health_res: healthRestrictions
        .filter(restriction => restriction.answer == true)
        .map(restriction => ({
          title: restriction.question,
          check_uncheck: restriction.answer ? "yes" : "no",
        })),
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitIncExc = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_inclusion_exclusion.php"
    const payload = {
      prodcut_id: productId,
      include_exclude: [
        ...IncExc.inclusions
          .filter(item => item.included)
          .map(item => ({
            title: item.description,
            type: "include",
          })),
        ...IncExc.exclusions
          .filter(item => item.included)
          .map(item => ({
            title: item.description,
            type: "exclude",
          })),
      ],
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitLang = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_guide_language.php"
    const payload = {
      prodcut_id: productId, // Assuming product ID is static; replace with dynamic value if necessary
      questions: [
        {
          question: "Do you offer in-person, audio or written guides?",
          answer:
            lang.offers === true
              ? "yes"
              : lang.offers === false
                ? "no"
                : lang.offers.length <= 0
                  ? ""
                  : "",
        },
        {
          question: "Are your guides licensed/certified?",
          answer:
            lang.guidesLicensed === true
              ? "yes"
              : lang.guidesLicensed === false
                ? "no"
                : lang.guidesLicensed <= 0
                  ? ""
                  : "",
        },
        {
          question: "Do your guides also serve as drivers?",
          answer:
            lang.guidesServeAsDrivers === true
              ? "yes"
              : lang.guidesServeAsDrivers === false
                ? "no"
                : lang.guidesServeAsDrivers <= 0
                  ? ""
                  : "",
        },
      ],
      languages: lang.languages.map(language => ({
        language: language.language,
        inperson: language.inPerson ? "yes" : "no",
        audio: language.audio ? "yes" : "no",
        written: language.written ? "yes" : "no",
      })),
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitItinerary = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_daywise.php"
    const payload = {
      prodcut_id: productId, // Ensure this is dynamically set based on your application's requirements
      daywise: itinerary.map(item => ({
        header: item.header,
        body: item.body,
        duration: item.duration,
        include: item.includes, // Ensure this matches your API expectation ('include' or 'includes')
      })),
    }

    console.log("ii", payload)

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Additional headers here if needed (e.g., authorization)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle successful submission (e.g., showing a success message)
    } catch (error) {
      console.error("Error submitting itinerary:", error)
      // Handle error case (e.g., showing an error message)
    }
  }
  const submitScheduleFormData = async e => {
    // e.preventDefault()

    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule.php"
    const scheduleData = {
      no_of_pax: noPax?.target.value,
      start_date: `${startDate?.target.value}`,
      end_date: `${endDate?.target.value}`,
      confirmation_type: confirmationType?.target.value, // Map "Automatic" to "yes", "Manually" to "no" or similar based on API requirements
      cutt_off_time: cutOffTime?.target.value,
      price_adult: priceAdult?.target.value,
      price_child: priceChild?.target.value,
      price_infant: priceInfant?.target.value,
      publish_type: publishType?.target.value, // You need to define how "Online", "Agent", "Both" map to your API's expected values
      promotion_discount_price: promotionDiscountPrice?.target.value,
      product_id: productId, // This needs to be dynamically set or selected from a dropdown
    } // Prepare your form data for the API

    // console.log(startDate.target.value, endDate)
    // console.log(scheduleData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other headers if needed
          },
          body: JSON.stringify(scheduleData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success (e.g., show success message)
    } catch (error) {
      console.error("Error submitting form:", error)
      // Handle error (e.g., show error message)
    }
  }
  function toggleTabVertical(tab) { 
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 9) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const fetchToAddress = async () => {
    const toadressobj = {
     
      route_id :newrouteid,
      type:"destination"
    };
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_pickup_destination_by_route.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(toadressobj),
        }
      )
      const data = await response.json()
      console.log( data);
      setViewToAddress(data || [])
    }
   
 

    const fetchFromAddress = async () => {
      const fromadressobj = {
       
        route_id :newrouteid,
        type:"pickup"
      };
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/transafer/get_pickup_destination_by_route.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(fromadressobj),
          }
        )
        const data = await response.json()
        console.log( data);
        setViewFromAddress(data || [])
      }
     
     

  const fetchvehicles = async () => {
    const vehicledata = {
     
      vehicles :"Yes"
      
    };
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/get_vehicle.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(vehicledata),
        }
      )
      const data = await response.json()
      console.log( data);
      setVehicles(data || [])
    }
   
    useEffect(() => {
      fetchvehicles()
    }, [])

  const fetchCountries = async () => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/transafer/get_country_state_city_list.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ get_country: "Yes" }),
      }
    )
    const data = await response.json()
    if (data) {
      console.log(data);
      setCountries(data || [])
    }
  }


  const fetchStates = async (cid) => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/transafer/get_state_by_country.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ country_id: cid }),
      }
    )
    const data = await response.json()
    setStates(Array.isArray(data) ? data : []);
  }
  const fetchCities = async (sid) => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/transafer/get_city_by_state.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ state_id: sid }),
      }
    )
    const data = await response.json()
    setCitiess(Array.isArray(data) ? data : []);
  }
 // Handle country selection change
const handleCountryChange = (e) => {
  const selectedCountryId = e.target.value;
  fetchStates(selectedCountryId);
  validation.handleChange(e); // Update form validation state
};
  const fetchData = async e => {
    try {
      const apiUrl =
        "https://login.flymiles.com.au/api_sections/tours/get_prodcut_complete_detail.php"

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: productId,
        }),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()
      setData(result)
      console.log(result.Product_Header.id) // Process the success response
    } catch (error) {
      console.error("Failed to submit the form", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [fetchImg])

  useEffect(() => {
    fetchCountries()
  }, [])

  useEffect(() => {
    fetchCountries()
  }, [])

  const handleEditorChange = newEditorState => {
    // const text = newEditorState.getCurrentContent().getPlainText()
    setEditorState(newEditorState)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Transfers" breadcrumbItem="Add Route" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Add Route</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1.</span> Route Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2.</span>{" "}
                            <span>Route Pricing</span>
                          </NavLink>
                        </NavItem>

                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                            <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Route Name
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    className="form-control"
                                    onChange={setProductName}
                                    id="basicpill-firstname-input12"
                                    placeholder="Enter Route Name"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-tourtype-input22">
                                    Transfer Type
                                  </Label>
                                  <select
                                    required
                                    className="form-control"
                                    onChange={e => setTourType(e.target.value)}
                                    value={tourType || ""}
                                    id="basicpill-tourtype-input22"
                                  >
                                    <option value="">
                                      Select Transfer Type
                                    </option>
                                    <option value="shared">Shared</option>
                                    <option value="private">Private</option>
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>Country</Label>
                                  <select
                                    className="form-select"
                                    value={countryId || ""}
                                    onChange={e => {
                                      const selectedCountryId = e.target.value;
                                      setCountryId(selectedCountryId); // Set the selected country ID
                                      fetchStates(selectedCountryId);   // Fetch states based on the selected country
                                    }}
                                  >
                                    <option>Select Country</option>
                                    {countries?.length > 0 ? (
                                      countries.map(country => (
                                        <option key={country.id} value={country.id}>
                                          {country.country}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>State</Label>
                                  <select
                                    className="form-select"
                                    value={stateId || ""} // Assuming you have a stateId for the selected state
                                    onChange={e => {
                                      const selectedStateId = e.target.value;
                                      setStateId(selectedStateId);  // Set the selected state
                                      fetchCities(selectedStateId); // Fetch cities based on the selected state
                                    }}
                                  >
                                    <option>Select State</option>
                                    {statesies?.length > 0 ? (
                                      statesies.map(state => (
                                        <option key={state.id} value={state.id}>
                                          {state.state}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>City</Label>
                                  <select
                                    className="form-select"
                                    value={cityId || ""} // Assuming you have a stateId for the selected state
                                    onChange={e => setCityId(e.target.value)} // Set the selected state
                                  >
                                    <option>Select City</option>
                                    {citiess?.length > 0 ? (
                                      citiess.map(city => (
                                        <option key={city.id} value={city.id}>
                                          {city.city}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                            <form>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>Point A</Label>
                                    <MyMultiSelectPointAComponent
                                      id={cityId} // Pass country ID or other ID if needed
                                      onChange={selectedCities => handlepointChange(selectedCities, "pointA")}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>Point B</Label>
                                    <MyMultiSelectPointBComponent
                                      id={cityId} // Pass country ID or other ID if needed
                                      onChange={selectedCities => handlepointChange(selectedCities, "pointB")}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </form>
                          </Form>
                        </TabPane>
                       
                        <TabPane tabId={2}>
                          <Form>
                            {/* Loop through all formData entries to render forms */}
                            {formData.map((data, index) => (
                              // Wrap the entire A to B and B to A routes in a bordered container
                              (index % 2 === 0) && (
                                <div key={index} className="dynamicform-with-border">
                                  <div className="dynamicform">
                                    {/* A to B Route */}
                                    <>
                                      <Row>
                                        <Col lg="6">
                                          <FormGroup className="mb-3">
                                            <Label>Vehicle</Label>
                                            <select
                                              className="form-select"
                                              value={data.vehicleId || ""}
                                              onChange={(e) => handlerouteChange(index, "vehicleId", e.target.value)}
                                            >
                                              <option value="">Select Vehicle</option>
                                              {viewvehicle?.map((vehicle) => (
                                                <option key={vehicle.vehicle_Header.id} value={vehicle.vehicle_Header.id}>
                                                  {vehicle.vehicle_Header.vehicle_type_des}
                                                </option>
                                              ))}
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                          <FormGroup className="mb-3">
                                            <Label>Transfer Type</Label>
                                            <select
                                              className="form-control"
                                              value={data.tourType || ""}
                                              onChange={(e) => {
                                                console.log("Selected tourType:", e.target.value);
                                                handlerouteChange(index, "tourType", e.target.value);
                                              }}
                                            >
                                              <option value="">Select Transfer Type</option>
                                              <option value="shared">Shared</option>
                                              <option value="private">Private</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="12">
                                          <h5 class="atob">Route: A to B</h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>From Address</Label>
                                            <select
                                              className="form-select"
                                              value={data.fromAddress || ""}
                                              onChange={(e) => handlerouteChange(index, "fromAddress", e.target.value)}
                                            >
                                              <option value="">Select From Address</option>
                                              {viewfromaddress?.map((fromadress, idx) => (
                                                <option key={`${fromadress.type}-${fromadress.address}-${idx}`} value={fromadress.address}>
                                                  {fromadress.address}
                                                </option>
                                              ))}
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>To Address</Label>
                                            <select
                                              className="form-select"
                                              value={data.toAddress || ""}
                                              onChange={(e) => handlerouteChange(index, "toAddress", e.target.value)}
                                            >
                                              <option value="">Select To Address</option>
                                              {viewtoaddress?.map((toadress, idx) => (
                                                <option key={`${toadress.type}-${toadress.address}-${idx}`} value={toadress.address}>
                                                  {toadress.address}
                                                </option>
                                              ))}
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>Price</Label>
                                            <Input
                                              type="number"
                                              className="form-control"
                                              value={data.routePrice || ""}
                                              onChange={(e) => handlerouteChange(index, "routePrice", e.target.value)}
                                              placeholder="Enter Route Price"
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      {formData[index].tourType === "private" ? (
                                        <Row>
                                          <Col lg="4">
                                            <FormGroup className="mb-3">
                                              <Label>Meet Greet</Label>
                                              <select
                                                className="form-control"
                                                value={formData[index].meet || ""}
                                                onChange={(e) => handlerouteChange(index, "meet", e.target.value)}
                                              >
                                                <option value="">Select Option</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                              </select>
                                            </FormGroup>
                                          </Col>
                                          <Col lg="4">
                                            <FormGroup className="mb-3">
                                              <Label>Water Bottle</Label>
                                              <select
                                                className="form-control"
                                                value={formData[index].water || ""}
                                                onChange={(e) => handlerouteChange(index, "water", e.target.value)}
                                              >
                                                <option value="">Select Option</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                              </select>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      ) : (
                                        <div style={{ visibility: 'hidden', color: "red" }}>tourType is not private</div>
                                      )}
                                    </>
                                  </div>

                                  {/* B to A Route */}
                                  {index + 1 < formData.length && (
                                    <div className="dynamicform">
                                      <Row>
                                        <Col lg="12">
                                          <h5 class="btoa">Route: B to A</h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>From Address</Label>
                                            <select
                                              className="form-select"
                                              disabled
                                              value={formData[index + 1].fromAddress || ""}
                                              onChange={(e) => handlerouteChange(index + 1, "fromAddress", e.target.value)}
                                            >
                                              <option value="">{formData[index].toAddress || "Select From Address"}</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>To Address</Label>
                                            <select
                                              className="form-select"
                                              disabled
                                              value={formData[index + 1].toAddress || ""}
                                              onChange={(e) => handlerouteChange(index + 1, "toAddress", e.target.value)}
                                            >
                                              <option value="">{formData[index].fromAddress || "Select To Address"}</option>
                                            </select>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mb-3">
                                            <Label>Price</Label>
                                            <Input
                                              type="number"
                                              className="form-control"
                                              value={formData[index + 1].routePrice || ""}
                                              onChange={(e) => handlerouteChange(index + 1, "routePrice", e.target.value)}
                                              placeholder="Enter Route Price"
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>

                                      {formData[index + 1].tourType === "private" ? (
                                        <Row>
                                          <Col lg="4">
                                            <FormGroup className="mb-3">
                                              <Label>Meet Greet</Label>
                                              <select
                                                className="form-control"
                                                value={formData[index + 1].meet || ""}
                                                onChange={(e) => handlerouteChange(index + 1, "meet", e.target.value)}
                                              >
                                                <option value="">Select Option</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                              </select>
                                            </FormGroup>
                                          </Col>
                                          <Col lg="4">
                                            <FormGroup className="mb-3">
                                              <Label>Water Bottle</Label>
                                              <select
                                                className="form-control"
                                                value={formData[index + 1].water || ""}
                                                onChange={(e) => handlerouteChange(index + 1, "water", e.target.value)}
                                              >
                                                <option value="">Select Option</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                              </select>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      ) : (
                                        <div style={{ visibility: 'hidden', color: "red" }}>tourType is not private</div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            ))}
                          </Form>
                          <Row>
                            <Col>
                              <FormGroup className="mb-3">
                                <Label>Pickup Instructions</Label>
                                <Input
                                  type="text"
                                  required
                                  className="form-control"
                                  onChange={(e) => setPickupinfo(e.target.value)}
                                  value={Pickupinfo}
                                  id="basicpill-firstname-input12"
                                  placeholder="Enter Pickup Instructions"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>


                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        {activeTabVartical < 8 ? (
                          <>
                            <li>
                              {activeTabVartical === 2 && (
                                <Link
                                  className="addbtn"
                                  to="#"
                                  onClick={() => { addMore(); }}
                                >
                                  Add More
                                </Link>
                              )}
                            </li>
                            <li
                              className={
                                activeTabVartical === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                        <li
                          className={
                            activeTabVartical === 9 ||
                              (activeTabVartical === 1 && isLoading === true) ||
                              (activeTabVartical === 2 && isLoading === true)
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (activeTabVartical === 1) {
                                handleSubmit()
                                // if (isLoading) {
                                // setIsLoading(false)
                                // }
                              } else if (activeTabVartical === 2) {
                                handlerouteSubmit()
                                
                              } else {
                              }
                            }}
                          >
                            {activeTabVartical === 1 ||
                              activeTabVartical === 2 ? (
                              <>{isLoading ? <>Loading...</> : <> Next</>}</>
                            ) : activeTabVartical === 8 ? (
                              <Link to="/tours">Finish</Link>
                            ) : (
                              <> Next</>
                            )}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateTour
