import React, { useState, useEffect } from "react"
import {
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  Row,
  Col,
} from "reactstrap"

const ItineraryItem = ({ item, dayNumber, onEdit, onDelete }) => (
  <Row style={{ borderBottom: "1px solid #ddd", marginBottom: 10 }}>
    <Col lg="10">
      <div>
        {/* <h4>Day - {dayNumber}</h4> */}
        <h5>{item.header}</h5>
        <p>{item.body}</p>
        <div style={{ display: "flex", gap: "30px" }}>
          {item.duration && <p>Duration: {item.duration}</p>}
          {item.includes && <p>Includes: {item.includes}</p>}
        </div>
      </div>
    </Col>
    <Col
      lg="2"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: 10,
        alignItems: "flex-start",
      }}
    >
      <Button color="success" onClick={() => onEdit(item.id)}>
        Edit
      </Button>
      <Button color="danger" onClick={() => onDelete(item.id)}>
        Delete
      </Button>
    </Col>
  </Row>
)

const predefinedItineraries = [
  // {
  //   id: "pre-1",
  //   header: "Sydney Harbour Bridge, Sydney, New South Wales 2060 Australia",
  //   body: "Pickup from your Hotel in Sydney CBD and around and then drive over the famous...",
  //   duration: "2 hours",
  //   includes: "Admission included",
  // },
  // {
  //   id: "pre-2",
  //   header: "Blue Mountains National Park, New South Wales",
  //   body: "Explore the natural wonders of the Blue Mountains, enjoy scenic views from the Three Sisters lookout, and take a ride on the Katoomba Scenic Railway.",
  //   duration: "Full day",
  //   includes: "Guided tour, transportation, and park entry fees",
  // },
  // {
  //   id: "pre-3",
  //   header: "Bondi Beach, Sydney, New South Wales",
  //   body: "Relax on the famous Bondi Beach, try out some surfing, or enjoy a coastal walk for some fresh sea air and stunning ocean views.",
  //   duration: "Half day",
  //   includes: "Beach access; surfboard rental available at additional cost",
  // },
  // {
  //   id: "pre-4",
  //   header: "Taronga Zoo, Sydney, New South Wales",
  //   body: "Visit the iconic Taronga Zoo, home to thousands of exquisite animals, including native Australian wildlife and rare and endangered species.",
  //   duration: "3-4 hours",
  //   includes: "Zoo admission, cable car ride, and seal show",
  // },
]

const ItineraryDayComponent = ({ onChange, data }) => {
  const [itinerary, setItinerary] = useState([])
  const [newItem, setNewItem] = useState({
    id: null,
    header: "",
    body: "",
    duration: "",
    includes: "",
  })

  const [showForm, setShowForm] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  // Merge predefined itineraries with incoming data, prioritizing incoming data
  useEffect(() => {
    const combinedItineraries = [...predefinedItineraries]
    data?.forEach(d => {
      const index = combinedItineraries.findIndex(it => it.id === d.id)
      const newItem = {
        ...d,
        id: `data-${d.id}`,
        header: d.header || "",
        body: d.body || "",
        duration: d.duration || "",
        includes: d.include || "", // Ensure includes has a default value
      }

      if (index > -1) {
        combinedItineraries[index] = {
          ...combinedItineraries[index],
          ...newItem,
        }
      } else {
        combinedItineraries.push(newItem)
      }
    })
    setItinerary(combinedItineraries)
  }, [data])

  // Initialize component with existing data if provided
  useEffect(() => {
    if (data && data.length > 0) {
      const initializedData = data.map(d => ({
        ...d,
        header: d.header || "",
        body: d.body || "",
        duration: d.duration || "",
        includes: d.include || "", // Map include to includes
      }))
      setItinerary(initializedData)
    }
  }, [data])

  useEffect(() => {
    onChange(itinerary)
  }, [itinerary, onChange])

  const handleInputChange = e => {
    const { name, value } = e.target
    setNewItem({ ...newItem, [name]: value })
  }

  const addOrEditItineraryItem = () => {
    if (newItem.header.trim() && newItem.body.trim()) {
      if (isEditing) {
        setItinerary(
          itinerary.map(item =>
            item.id === newItem.id ? { ...item, ...newItem } : item
          )
        )
      } else {
        setItinerary([...itinerary, { ...newItem, id: Date.now().toString() }])
      }
      setNewItem({ id: null, header: "", body: "", duration: "", includes: "" })
      setShowForm(false)
      setIsEditing(false)
    } else {
      alert("Please fill in at least the header and body for the new item.")
    }
  }

  const handleEditItem = id => {
    const itemToEdit = itinerary.find(item => item.id === id)
    setNewItem(itemToEdit)
    setShowForm(true)
    setIsEditing(true)
  }

  const handleDeleteItem = id => {
    setItinerary(itinerary.filter(item => item.id !== id))
  }

  const renderForm = () => (
    <>
      <FormGroup>
        {/* <h2>Day - {isEditing ? "Edit" : `Add New Day`}</h2> */}
        <Label for="header">Header:</Label>
        <Input
          id="header"
          name="header"
          value={newItem.header}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="body">Body:</Label>
        <Input
          type="textarea"
          id="body"
          name="body"
          value={newItem.body}
          onChange={handleInputChange}
          rows={4}
        />
      </FormGroup>
      <FormGroup>
        <Label for="duration">Duration:</Label>
        <Input
          id="duration"
          name="duration"
          value={newItem.duration}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="includes">Includes:</Label>
        <Input
          id="includes"
          name="includes"
          value={newItem.includes}
          onChange={handleInputChange}
        />
      </FormGroup>
      <Button color="success" onClick={addOrEditItineraryItem}>
        {isEditing ? "Save Changes" : "Add Day"}
      </Button>
    </>
  )

  return (
    <TabPane tabId="itinerary">
      <ListGroup>
        {itinerary.map((item, index) => (
          <ItineraryItem
            key={item.id}
            item={item}
            dayNumber={index + 1}
            onEdit={handleEditItem}
            onDelete={handleDeleteItem}
          />
        ))}
      </ListGroup>
      {showForm ? (
        renderForm()
      ) : (
        <Button
          color="primary"
          onClick={() => {
            setShowForm(true)
            setIsEditing(false)
          }}
        >
          Add New Day
        </Button>
      )}
    </TabPane>
  )
}

export default ItineraryDayComponent
