// import React, { useCallback, useEffect, useState } from "react"
// import { useDropzone } from "react-dropzone"
// import { Button, Alert } from "reactstrap"

// const ImageUploader = ({ handleImage, data }) => {
//   const [files, setFiles] = useState([])
//   const [isWarningVisible, setIsWarningVisible] = useState(false)

//   // console.log(files)
//   const onDrop = useCallback(
//     acceptedFiles => {
//       if (acceptedFiles.length + files.length > 6) {
//         setIsWarningVisible(true)
//         return
//       }
//       setIsWarningVisible(false) // Reset warning state

//       acceptedFiles.forEach(file => {
//         const reader = new FileReader()
//         reader.onloadend = () => {
//           const base64String = reader.result
//           setFiles(prevFiles => [
//             ...prevFiles,
//             { name: file.name, preview: base64String },
//           ])
//         }
//         reader.readAsDataURL(file)
//       })
//     },
//     [files]
//   )

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "image/*",
//     onDrop: onDrop,
//     noClick: files.length >= 6, // Prevent file dialog on click if limit is reached
//     noKeyboard: files.length >= 6,
//   })

//   const deleteExtraImage = fileName => {
//     setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName))
//   }

//   // Cleanup previews
//   useEffect(() => {
//     // Map each file to an array containing two objects: one with the image and one with the type
//     const imagesWithType = files.map(file => ({
//       image: file.preview,
//       type: "others",
//     }))

//     handleImage(imagesWithType)
//     // console.log(imagesWithType)
//   }, [files, handleImage, onDrop])

//   return (
//     <div>
//       {isWarningVisible && (
//         <Alert color="danger">You can only upload up to 6 images.</Alert>
//       )}
//       <div
//         {...getRootProps({ className: "dropzone" })}
//         style={{ width: "400px", border: "2px dashed #bbb" }}
//       >
//         <input {...getInputProps()} />
//         <div style={{ padding: "30px", textAlign: "center" }}>
//           <i className="fa fa-upload" style={{ fontSize: "24px" }}></i>
//           <p>Drag 'n' drop files here, or click to browse</p>
//           <p>Maximum file size is 100MB.</p>
//         </div>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           gap: "10px",
//           flexWrap: "wrap",
//           marginTop: 20,
//         }}
//       >
//         {files.map((file, index) => (
//           <div
//             key={index}
//             style={{ position: "relative", width: "100px", height: "100px" }}
//           >
//             <img
//               src={file.preview}
//               alt={file.name}
//               style={{ width: "100%", height: "100%", objectFit: "cover" }}
//             />
//             <Button
//               close
//               type="button"
//               style={{ position: "absolute", top: 0, right: 0 }}
//               onClick={() => deleteExtraImage(file.name)}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   )
// }

// export default ImageUploader

import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Button, Alert } from "reactstrap"

const ImageUploader = ({ handleImage, data }) => {
  const [files, setFiles] = useState([])

  // Initialize with existing data if present
  useEffect(() => {
    if (data && data.length > 0) {
      const existingFiles = data
        .filter(({ type }) => type === "others") // Filter for "others" type
        .map(file => {
          // Extract filename from the 'image' path or URL
          const fileName = file.image.split("/").pop()
          // Construct the full URL for the image
          const imageUrl = `https://login.flymiles.com.au/api_sections/packages/pkg_img/${fileName}`

          return {
            name: fileName,
            preview: imageUrl, // Use the constructed URL for the preview
          }
        })

      setFiles(existingFiles)
    }
  }, [data])

  const [isWarningVisible, setIsWarningVisible] = useState(false)

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length + files.length > 6) {
        setIsWarningVisible(true)
        return
      }
      setIsWarningVisible(false) // Reset warning state

      acceptedFiles.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result
          setFiles(prevFiles => [
            ...prevFiles,
            { name: file.name, preview: base64String },
          ])
        }
        reader.readAsDataURL(file)
      })
    },
    [files]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: onDrop,
    noClick: files.length >= 6, // Prevent file dialog on click if limit is reached
    noKeyboard: files.length >= 6,
  })

  const deleteExtraImage = fileName => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName))
  }

  // Cleanup previews and update parent component
  useEffect(() => {
    // Map each file to an array containing two objects: one with the image and one with the type
    const imagesWithType = files.map(file => ({
      image: file.preview,
      type: "others",
    }))

    handleImage(imagesWithType)
  }, [files, handleImage])

  return (
    <div>
      {isWarningVisible && (
        <Alert color="danger">You can only upload up to 6 images.</Alert>
      )}
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ width: "400px", border: "2px dashed #bbb" }}
      >
        <input {...getInputProps()} />
        <div style={{ padding: "30px", textAlign: "center" }}>
          <i className="fa fa-upload" style={{ fontSize: "24px" }}></i>
          <p>Drag 'n' drop files here, or click to browse</p>
          <p>Maximum file size is 500KB.</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: 20,
        }}
      >
        {files.map((file, index) => (
          <div
            key={index}
            style={{ position: "relative", width: "100px", height: "100px" }}
          >
            <img
              src={file.preview}
              alt={file.name}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <Button
              close
              type="button"
              style={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => deleteExtraImage(file.name)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImageUploader
