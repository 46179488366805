import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Input,
  Row,
  Alert,
} from "reactstrap"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const TwostepVerification = () => {
  // const
  const navigate = useNavigate()
  // const [combinedValue, setCombinedValue] = useState("")
  const [userotp, setUserotp] = useState("")
  const [useremail, setUserEmail] = useState("")
  const [isLoading, setIsLoading] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    // Retrieve the item from local storage
    const API = localStorage.getItem("apiToken")
    const json = JSON.parse(API)
    const storedOtp = json.otp
    const email = json.EMAIL

    setUserEmail(email)
    console.log(storedOtp)
    // Set the retrieved item in the state
    if (storedOtp) {
      setUserotp(storedOtp)
    }
  }, [])
  const handleSubmit = event => {
    event.preventDefault()
    setIsLoading(true)

    // Get the values from the input fields
    const digit1 = event.target.elements.digit1.value
    const digit2 = event.target.elements.digit2.value
    const digit3 = event.target.elements.digit3.value
    const digit4 = event.target.elements.digit4.value

    // Combine the values into a single variable
    const combined = digit1 + digit2 + digit3 + digit4
    console.log(combined)
    console.log(combined == userotp)
    // Save the combined value to state
    // setCombinedValue(combined)

    // Navigate to /dashboard
    if (combined === userotp) {
      navigate("/dashboard")
      setIsLoading(false)
    } else if (combined === "") {
      setError("PLease Enter OTP Code")
      setIsLoading(false)
    } else {
      setError("Invalid OTP, PLease Enter OTP Code Again")
      setIsLoading(false)
    }
  }
  // console.log("userOTP" + userotp)
  // console.log("useremail " + useremail)

  //meta title
  document.title = "Two Step Verification | Flymiles"

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              {/* <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">Two Step Authentication</p>
              </div> */}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your email</h4>
                        <p className="mb-5">
                          Please enter the 4 digit code sent to{" "}
                          <span className="fw-semibold">{useremail}</span>
                        </p>

                        <Form onSubmit={handleSubmit}>
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          <Row>
                            <Col className="col-3">
                              <div className="mb-3">
                                <Label
                                  htmlFor="digit1-input"
                                  className="visually-hidden"
                                >
                                  Dight 1
                                </Label>
                                <Input
                                  type="text"
                                  name="digit1"
                                  className="form-control form-control-lg text-center two-step"
                                  maxLength="1"
                                  data-value="1"
                                  id="digit1-input"
                                />
                              </div>
                            </Col>

                            <Col>
                              <div className="mb-3">
                                <Label
                                  htmlFor="digit2-input"
                                  className="visually-hidden"
                                >
                                  Dight 2
                                </Label>
                                <Input
                                  type="text"
                                  name="digit2"
                                  className="form-control form-control-lg text-center two-step"
                                  maxLength="1"
                                  data-value="2"
                                  id="digit2-input"
                                />
                              </div>
                            </Col>

                            <Col>
                              <div className="mb-3">
                                <Label
                                  htmlFor="digit3-input"
                                  className="visually-hidden"
                                >
                                  Dight 3
                                </Label>
                                <Input
                                  type="text"
                                  name="digit3"
                                  className="form-control form-control-lg text-center two-step"
                                  maxLength="1"
                                  data-value="3"
                                  id="digit3-input"
                                />
                              </div>
                            </Col>

                            <Col>
                              <div className="mb-3">
                                <Label
                                  htmlFor="digit4-input"
                                  className="visually-hidden"
                                >
                                  Dight 4
                                </Label>
                                <Input
                                  type="text"
                                  name="digit4"
                                  className="form-control form-control-lg text-center two-step"
                                  maxLength="1"
                                  data-value="4"
                                  id="digit4-input"
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-4">
                            <button
                              type="submit"
                              // to="/dashboard"
                              className="btn btn-success w-md"
                              disabled={isLoading}
                            >
                              {isLoading ? "Checking..." : "Confirm"}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Did&apos;t receive a code ?{" "}
                  <a href="#" className="fw-medium text-primary">
                    {" "}
                    Resend{" "}
                  </a>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} All Rights Reserved{" "}
                  {/* <i className="mdi mdi-heart text-danger" />  */}
                  by FLY MILES PTY. LTD.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TwostepVerification
