import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import classnames from "classnames"
import { useNavigate } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "pages/JobPages/JobList/JobListCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button,
} from "reactstrap"
import { useDropzone } from "react-dropzone"
// import DayWiseItinerary from "./DayWiseItinerary "
// import AccessibilityForm from "./AccessibilityOptions"
// import HealthRestrictionsForm from "./HealthRestrictions"
// import ItineraryDayComponent from "./ItineraryDayComponent "
import ImageUploadComponent from "./ImageUploadComponent"
//import ImageUploader from "./StyledDropzone"
// import MyMultiSelectComponent from "./MyMultiselectComponent"
// import Inclusions from "./Inclusions"
// import MyEditor from "./EditorForm"
// import InclusionExclusionForm from "./InclusionAndExclusion"
// import GuideLanguagesForm from "./GuideAndLanguages"
// import { JobNo } from "pages/JobPages/JobList/JobListCol"

function CreateTour() {
  const navigate = useNavigate(); // Initialize navigate
  //meta title
  document.title = "Create a Package | Flymiles"
  const [data, setData] = useState([])
  const [warning, setWarning] = useState("")
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [countries, setCountries] = useState(null)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [productId, setProductId] = useState(null)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [productName, setProductName] = useState("")
  const [productCode, setProductCode] = useState("")
  const [countryId, setCountryId] = useState("")
  const [newvehicleid, setnewvehicleid] = useState("")
  const [duration, setDuration] = useState("")
  const [luggage, setlugg] = useState("")
  const [description, setProductDes] = useState("")

  const [tourType, setTourType] = useState("")
  const [cities, setCities] = useState([])
  const [inclusions, setInclusions] = useState([])
  const [image, setImage] = useState([])
  const [multiImages, setMultiImages] = useState([])
  const [accessibilityQuestions, setAccessibilityQuestions] = useState([])
  const [healthRestrictions, setHealthRestrictions] = useState([])
  const [IncExc, setIncExc] = useState([])
  const [lang, setLang] = useState([])
  const [itinerary, setItinerary] = useState([])
  const [selectProduct, setselectProduct] = useState("")
  const [noPax, setNoPax] = useState(0)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [confirmationType, setConfirmationType] = useState("")
  const [cutOffTime, setcutOffTime] = useState("")
  const [priceChild, setPriceChild] = useState("")
  const [priceAdult, setPriceAdult] = useState("")
  const [priceInfant, setPriceInfant] = useState("")
  const [productListing, setProductListing] = useState("")
  const [publishType, setPublishType] = useState("")
  const [promotionDiscountPrice, setPromotionDiscountPrice] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [fetchImg, setFetchImg] = useState(false)

  const handleCityChange = selectedCities => {
    console.log(selectedCities)
    // Assuming you want to store just the values of selected options
    const cities = selectedCities.map(city => city.value)
    setCities(cities)
  }
  const handleInclusionsChange = inclusions => {
    setInclusions(inclusions)
    // console.log("incl", inclusions)
  }
  const handleAcc = Acc => {
    setAccessibilityQuestions(Acc)
    // console.log("incl", inclusions)
  }
  const handleHR = HR => {
    setHealthRestrictions(HR)
    // console.log("incl", inclusions)
  }
  const handleIncExc = IE => {
    setIncExc(IE)
  }
  const handleLang = lang => {
    setLang(lang)
    // console.log("incl", lang)
  }
  const handleDay = day => {
    setItinerary(day)
    // console.log("incl", inclusions)
  }
  const handleMainImage = image => {
    // console.log("i2", image)
    setImage(image)
  }
  const handleImage = image => {
    // console.log("i", image)
    setMultiImages(image)
  }

  const submitImages = async () => {
    // e.preventDefault()
    setIsLoading(true)
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/transafer/add_images.php"

    const payload = {
      vehicle_id: newvehicleid,
      images: image,
      // images: multiImages,
    }

    console.log(payload)

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      if (data) {
        setIsLoading(false)
        setFetchImg(false)
        
        navigate("/allvehicle");
        alert(data.success);
      
      }
      // Handle success response
    } catch (error) {
      console.error("Error submitting images:", error)
      setIsLoading(false)
      setFetchImg(false)

      // Handle errors here
    }
  }
  const handleSubmit = async e => {
    // e.preventDefault()
    // const productInclusionsHigh = draftToHtml(
    //   convertToRaw(editorState.getCurrentContent())
    // )
    setIsLoading(true)

    const formData = {
      product_name: productName?.target?.value,
      product_code: productCode?.target?.value,
      durations: duration?.target?.value,
      luggage: luggage?.target?.value,
      description: description?.target?.value,
      county_id: parseInt(countryId),

    }
    console.log(formData)
    const updateFormData = {

      make: productName?.target?.value,
      model: productCode?.target?.value,
      max_pax: duration?.target?.value,
      max_lugg: luggage?.target?.value,
      vehicle_type_des: description?.target?.value,
      vehicle_type_id: countryId,
    }
    console.log(updateFormData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/transafer/add_vehicle.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFormData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Success:", result);
      if(result.error){
        setIsLoading(false)
        toggleTabVertical(activeTabVartical)
      }else{
        setnewvehicleid(result.vehicle_id);
        console.log(result.vehicle_id);
        setIsLoading(false)
        toggleTabVertical(activeTabVartical + 1)
      }
    
    } catch (error) {
      setIsLoading(false)
      console.error("Error during API call:", error);
      // Optionally show an error message
      alert("Failed to save the landmark. Please try again.");
    }

    // try {

    //   const apiUrl =
    //     "https://login.flymiles.com.au/api_sections/transafer/add_vehicle.php"
    //   const updateApiUrl =
    //     "https://login.flymiles.com.au/api_sections/tours/update_main_product_api.php"
    //   const api = productId !== null ? updateApiUrl : apiUrl

    //   const response = await fetch(api, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(updateFormData),
    //   })

    //   if (!response.ok) {
    //     throw new Error(`Error: ${response.statusText}`)
    //   }

    //   const result = await response.json()
    //   if (result.prodcut_id) {
    //     setProductId(result.prodcut_id)
    //   }

    //   if (result) {
    //     setIsLoading(false)
    //     toggleTabVertical(activeTabVartical + 1)
    //   }

    //   console.log(result) // Process the success response
    //   console.log(result.prodcut_id) // Process the success response
    // } catch (error) {
    //   setIsLoading(false)

    //   console.error("Failed to submit the form", error)
    // }
  }
  const submitAccess = async () => {
    // Prepare the data in the required API format
    const accessibilityData = {
      prodcut_id: productId, // Ensure this ID is dynamically set based on your needs
      accessibility: accessibilityQuestions.map(q => ({
        title: q.question,
        yes_no: q.answer,
      })),
    }

    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/add_accessibility.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any required headers here
          },
          body: JSON.stringify(accessibilityData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      console.log("Success:", result)
      // Handle success response
    } catch (error) {
      console.error("Error during API call:", error)
      // Handle errors
    }
  }
  const submitHealthRes = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_health_restriction.php"
    const payload = {
      prodcut_id: productId, // Assuming product ID is static; replace with dynamic value if necessary
      health_res: healthRestrictions
        .filter(restriction => restriction.answer == true)
        .map(restriction => ({
          title: restriction.question,
          check_uncheck: restriction.answer ? "yes" : "no",
        })),
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitIncExc = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_inclusion_exclusion.php"
    const payload = {
      prodcut_id: productId,
      include_exclude: [
        ...IncExc.inclusions
          .filter(item => item.included)
          .map(item => ({
            title: item.description,
            type: "include",
          })),
        ...IncExc.exclusions
          .filter(item => item.included)
          .map(item => ({
            title: item.description,
            type: "exclude",
          })),
      ],
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitLang = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_guide_language.php"
    const payload = {
      prodcut_id: productId, // Assuming product ID is static; replace with dynamic value if necessary
      questions: [
        {
          question: "Do you offer in-person, audio or written guides?",
          answer:
            lang.offers === true
              ? "yes"
              : lang.offers === false
                ? "no"
                : lang.offers.length <= 0
                  ? ""
                  : "",
        },
        {
          question: "Are your guides licensed/certified?",
          answer:
            lang.guidesLicensed === true
              ? "yes"
              : lang.guidesLicensed === false
                ? "no"
                : lang.guidesLicensed <= 0
                  ? ""
                  : "",
        },
        {
          question: "Do your guides also serve as drivers?",
          answer:
            lang.guidesServeAsDrivers === true
              ? "yes"
              : lang.guidesServeAsDrivers === false
                ? "no"
                : lang.guidesServeAsDrivers <= 0
                  ? ""
                  : "",
        },
      ],
      languages: lang.languages.map(language => ({
        language: language.language,
        inperson: language.inPerson ? "yes" : "no",
        audio: language.audio ? "yes" : "no",
        written: language.written ? "yes" : "no",
      })),
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitItinerary = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_daywise.php"
    const payload = {
      prodcut_id: productId, // Ensure this is dynamically set based on your application's requirements
      daywise: itinerary.map(item => ({
        header: item.header,
        body: item.body,
        duration: item.duration,
        include: item.includes, // Ensure this matches your API expectation ('include' or 'includes')
      })),
    }

    console.log("ii", payload)

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Additional headers here if needed (e.g., authorization)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle successful submission (e.g., showing a success message)
    } catch (error) {
      console.error("Error submitting itinerary:", error)
      // Handle error case (e.g., showing an error message)
    }
  }
  const submitScheduleFormData = async e => {
    // e.preventDefault()

    const apiUrl =
      "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule.php"
    const scheduleData = {
      no_of_pax: noPax?.target.value,
      start_date: `${startDate?.target.value}`,
      end_date: `${endDate?.target.value}`,
      confirmation_type: confirmationType?.target.value, // Map "Automatic" to "yes", "Manually" to "no" or similar based on API requirements
      cutt_off_time: cutOffTime?.target.value,
      price_adult: priceAdult?.target.value,
      price_child: priceChild?.target.value,
      price_infant: priceInfant?.target.value,
      publish_type: publishType?.target.value, // You need to define how "Online", "Agent", "Both" map to your API's expected values
      promotion_discount_price: promotionDiscountPrice?.target.value,
      product_id: productId, // This needs to be dynamically set or selected from a dropdown
    } // Prepare your form data for the API

    // console.log(startDate.target.value, endDate)
    // console.log(scheduleData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other headers if needed
          },
          body: JSON.stringify(scheduleData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success (e.g., show success message)
    } catch (error) {
      console.error("Error submitting form:", error)
      // Handle error (e.g., show error message)
    }
  }
  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 9) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  const fetchCountries = async () => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/transafer/get_vehicle_type.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ vehicle_type: "Yes", own_page: "Yes" }),
      }
    )
    const data = await response.json()
    if (data) {
      console.log(data);
      setCountries(data || [])
    }
  }
  const fetchData = async e => {
    try {
      const apiUrl =
        "https://login.flymiles.com.au/api_sections/tours/get_prodcut_complete_detail.php"

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: productId,
        }),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()
      setData(result)
      console.log(result.Product_Header.id) // Process the success response
    } catch (error) {
      console.error("Failed to submit the form", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [fetchImg])

  useEffect(() => {
    fetchCountries()
  }, [])

  const handleEditorChange = newEditorState => {
    // const text = newEditorState.getCurrentContent().getPlainText()
    setEditorState(newEditorState)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Transfers" breadcrumbItem="Add Vehicle" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Add Vehicle</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1.</span> Vehicle Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2.</span>{" "}
                            <span>Images</span>
                          </NavLink>
                        </NavItem>

                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>Vehicle Type</Label>
                                  <select
                                    // required
                                    className="form-select"
                                    value={countryId || ""}
                                    onChange={e => setCountryId(e.target.value)}
                                  >
                                    <option> Select Type</option>
                                    {countries?.length > 0 ? (
                                      <>
                                        {countries?.map(country => (
                                          <option
                                            key={country.id}
                                            value={country.id}
                                          >
                                            {country.type}
                                          </option>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Make
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    className="form-control"
                                    onChange={setProductName}
                                    id="basicpill-firstname-input12"
                                    placeholder="Enter Vehicle Make"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-Productcode-input22">
                                    Model
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    className="form-control"
                                    onChange={setProductCode}
                                    id="basicpill-Productcode-input22"
                                    placeholder="Enter Vehicle Model"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-duration-input12">
                                    Maximum Pessenger
                                  </Label>
                                  <Input
                                    type="number"
                                    required
                                    className="form-control"
                                    onChange={setDuration}
                                    id="basicpill-duration-input12"
                                    placeholder="Enter Maximum Pessenger"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-duration-input12">
                                    Maximum Luggage
                                  </Label>
                                  <Input
                                    type="number"
                                    required
                                    className="form-control"
                                    onChange={setlugg}
                                    id="basicpill-duration-input12"
                                    placeholder="Enter Maximum Luggage"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Vehicle Shape
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    className="form-control"
                                    onChange={setProductDes}
                                    id="basicpill-firstname-input12"
                                    placeholder="Enter Vehicle Shape"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="product-main-image">
                                      Vehicle Image: (Main Image)
                                    </Label>
                                    <ImageUploadComponent
                                      handleMainImage={handleMainImage}
                                      data={data?.mainImage}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        {activeTabVartical < 8 ? (
                          <>
                            <li
                              className={
                                activeTabVartical === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                        <li
                          className={
                            activeTabVartical === 9 ||
                              (activeTabVartical === 1 && isLoading === true) ||
                              (activeTabVartical === 2 && isLoading === true)
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (activeTabVartical === 1) {
                                handleSubmit()
                                // if (isLoading) {
                                // setIsLoading(false)
                                // }
                              } else if (activeTabVartical === 2) {
                                submitImages(), setFetchImg(true)
                                console.log("images")
                                // toggleTabVertical(activeTabVartical + 1)
                              } else {
                              }
                            }}
                          >
                            {activeTabVartical === 1 ||
                              activeTabVartical === 2 ? (
                              <>{isLoading ? <>Loading...</> : <> Next</>}</>
                            ) : activeTabVartical === 8 ? (
                              <Link to="/tours">Finish</Link>
                            ) : (
                              <> Next</>
                            )}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateTour
