import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { isEmpty } from "lodash"

import { useSelector, useDispatch } from "react-redux"
import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"
import DeleteModal from "../../components/Common/DeleteModal"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap"

//import MultiSelectTimes from "./MultiSelectTimes"
//import TimeGroupBuilder from "./TimeGroupBuilder "
//import PriceGroupBuilder from "./PriceGroupBuilder"

const timesWithoutGroups = [{ time: "7.30 PM" }, { time: "7.50 PM" }]

function Tours() {
  const [modal, setModal] = useState(false)
  const [data2, setData] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedForm, setSelectedForm] = useState(
    filteredData.length > 0 ? filteredData[0].product_pricing : ""
  )
  const [productId, setProductId] = useState("")
  const [selectedTimes, setSelectedTimes] = useState([])
  const [selectedPrices, setSelectedPrices] = useState([])
  const [timeGroups, setTimeGroups] = useState([])
  const [priceGroups, setPriceGroups] = useState([])

  const handlePriceGroupsChange = newPriceGroups => {
    setPriceGroups(newPriceGroups)
  }
  console.log(timeGroups)
  const handleTimeGroupsChange = newTimeGroups => {
    setTimeGroups(newTimeGroups)
    console.log(newTimeGroups)
  }

  const handleSelection = event => {
    setSelectedForm(event.target.value)
  }

  const handleTimesChange = selectedTimes => {
    setSelectedTimes(selectedTimes)
    console.log(selectedTimes)
  }
  const handlePricesChange = selectedPrices => {
    setSelectedPrices(selectedPrices)
  }

  useEffect(() => {
    if (filteredData.length > 0) {
      setSelectedForm(filteredData[0].product_pricing)
    }
  }, [filteredData])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      pax: (job && job.pax) || filteredData.map(item => item.no_of_pax) || "",
      cuttOffTime:
        (job && job.cuttOffTime) ||
        filteredData.map(item => item.cutt_off_time) ||
        "",
      startDate:
        (job && job.startDate) ||
        filteredData.map(item => item.start_date) ||
        "",
      endDate:
        (job && job.endDate) || filteredData.map(item => item.end_date) || "",
      confirmation:
        (job && job.confirmation) ||
        filteredData.map(item => item.confirmation_type) ||
        "",
      priceChild:
        (job && job.priceChild) ||
        filteredData.map(item => item.price_child) ||
        "",
      priceInfant:
        (job && job.priceInfant) ||
        filteredData.map(item => item.price_infant) ||
        "",
      priceAdult:
        (job && job.priceAdult) ||
        filteredData.map(item => item.price_adult) ||
        "",
      discount:
        (job && job.discount) ||
        filteredData.map(item => item.promotion_discount_price) ||
        "",
      productListing:
        (job && job.prodcut_listing) ||
        filteredData.map(item => item.prodcut_listing) ||
        "",
      productPricing:
        (job && job.productPricing) ||
        (filteredData.length > 0 && filteredData[0].product_pricing) ||
        selectedForm ||
        "",

      cancel:
        (job && job.cancel) ||
        filteredData.map(item => item.cancell_policy) ||
        "",
      times: (job && job.times) || filteredData.map(item => item.times) || "", // filteredData.map(item => item.time) ||
      timesGroup:
        (job && job.timesGroup) || filteredData.map(item => item.times) || "", // filteredData.map(item => [{time:item.time, group:item.group}]) ||
      prices:
        (job && job.prices) || filteredData.map(item => item.prices) || "",
      publish:
        (job && job.publish) ||
        filteredData.map(item => item.publish_type) ||
        "",
      popular:
        (job && job.popular) ||
        filteredData.map(item => item.popular_tour) ||
        "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      if (isEdit) {
        const payload = {
          country_id: job.id, // Assuming 'job.id' holds the country ID you want to update
          country: values.jobTitle, // Assuming 'jobTitle' is the updated country name
        }
        console.log(values.jobTitle, job.jobTitle) //
        const updateJobList = {
          id: job ? job.id : 0,
          // jobId: values.jobId,
          jobTitle: values.jobTitle,
          companyName: values.companyName,
          // location: values.location,
          // experience: values.experience,
          // position: values.position,
          // type: values.type,
          // postedDate: "02 June 2021",
          // lastDate: "25 June 2021",
          // status: values.status,
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/packages/update_country.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other headers as required by your API
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Handle success response
            // You might want to update your local state or Redux store based on the response
            // For example, refreshing the country list to reflect the updated data
            validation.resetForm()
            toggle()
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle errors here, possibly showing an error message to the user
          })

        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const payload = {
          country: values.jobTitle, // Assuming the jobTitle field is used for country name
        }

        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          jobTitle: values["jobTitle"],
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/packages/add_country.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other headers as required by the API
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Handle success response
            // You might want to update your local state or Redux store based on the response
            // For example, refetching the country list if the API does not automatically do it
            validation.resetForm()
            toggle()
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle errors here
          })

        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      fetchData()
      toggle()
    },
  })

  console.log(
    filteredData.map(item => item.prices),
    filteredData
  )
  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }


  const handleEnableDisableCountry = async (countryId, status) => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/transafer/disable_enable_vehicle.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          disbale_disable:status, 
          vehicle_id:countryId
        }),
      }
    )
    const data = await response.json()
    console.log(data) // Handle the response if necessary
    fetchData() // Refresh the list after the change
  }

  const submitScheduleFormData = async e => {
    // const apiUrl =
    //   "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule.php"
    const scheduleData = {
      no_of_pax: Array.isArray(validation.values.pax)
        ? validation.values.pax[0]
        : validation.values.pax,
      start_date: Array.isArray(validation.values.startDate)
        ? validation.values.startDate[0]
        : validation.values.startDate,
      end_date: Array.isArray(validation.values.endDate)
        ? validation.values.endDate[0]
        : validation.values.endDate,
      confirmation_type: Array.isArray(validation.values.confirmation)
        ? validation.values.confirmation[0]
        : validation.values.confirmation, // Map "Automatic" to "yes", "Manually" to "no" or similar based on API requirements
      cutt_off_time: Array.isArray(validation.values.cuttOffTime)
        ? validation.values.cuttOffTime[0]
        : validation.values.cuttOffTime,
      publish_type: Array.isArray(validation.values.publish)
        ? validation.values.publish[0]
        : validation.values.publish,
      popular_tour: Array.isArray(validation.values.popular)
        ? validation.values.popular[0]
        : validation.values.popular,
      promotion_discount_price: Array.isArray(validation.values.discount)
        ? validation.values.discount[0]
        : validation.values.discount,
      prodcut_listing: Array.isArray(validation.values.productListing)
        ? validation.values.productListing[0]
        : validation.values.productListing,

      cancell_policy: Array.isArray(validation.values.cancel)
        ? validation.values.cancel[0]
        : validation.values.cancel,
      product_pricing: Array.isArray(validation.values.productPricing)
        ? validation.values.productPricing[0]
        : validation.values.productPricing,
      product_id: productId,
    }

    const personData = {
      ...scheduleData,
      price_adult: Array.isArray(validation.values.priceAdult)
        ? validation.values.priceAdult[0]
        : validation.values.priceAdult,
      price_child: Array.isArray(validation.values.priceChild)
        ? validation.values.priceChild[0]
        : validation.values.priceChild,
      price_infant: Array.isArray(validation.values.priceInfant)
        ? validation.values.priceInfant[0]
        : validation.values.priceInfant,
      times: selectedTimes.map(time => ({ time: time.time })),
    }
    const vehicleData = {
      ...scheduleData,
      times: timeGroups.map(time => ({
        time: time.time,
        group: time.group,
      })),
      prices: priceGroups.map(price => ({
        person_type: price.person_type,
        group: price.group,
        px_min: price.px_min,
        px_max: price.px_max,
        price: price.price,
      })),
    }

    console.log(vehicleData, selectedForm)
    let pricingapi
    if (selectedForm === "Per Person") {
      pricingapi =
        "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule.php"
    } else {
      pricingapi =
        "https://login.flymiles.com.au/api_sections/tours/add_pricing_schedule_vehicle.php"
    }
    try {
      const response = await fetch(pricingapi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          selectedForm === "Per Person" ? personData : vehicleData
        ),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      if (response) {
        window.location.reload()

        fetchData()
        console.log("rerender")

        setModal(false)
        setLoading(false)
      }
    } catch (error) {
      console.error("Error submitting form:", error)
    }
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedPkg, setSelectedPkg] = useState(false)
  const handleDeletePackage = async () => {
    if (!selectedPkg) return

    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/tours/delete_complete_pkg.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ product_id: selectedPkg }),
      }
    )
    const data = await response.json()
    console.log(data) // You might want to handle this depending on the response
    fetchData() // Refresh the list after deletion
    setDeleteModal(false)
  }

  const openDeleteModal = id => {
    setSelectedPkg(id)
    setDeleteModal(true)
  }
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Route Name",
        accessor: "rname",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "City",
        accessor: "city",
      },
      { Header: "Action", accessor: "action" },

      {
        Header: "Alldata",
        accessor: "allData",
      },
    ]
    return baseColumns
  }, [])

  
 

  const fetchData = () => {
    setIsLoading(true)
  
    return fetch(
      "https://login.flymiles.com.au/api_sections/transafer/get_all_routes.php",
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
       
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(apidata => {
        console.log(apidata)
      
        if (apidata && apidata.length > 0) {
          const updatedData = [
            Array.isArray(data2) ? data2 : [], // Ensure data2 is iterable
            ...apidata.slice(0, apidata.length).map(data => ({
              rname: (
                <>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: 20 }}
                >
                  {/* <div style={{}}>
                    <img src={data.images[0].img}
                      style={{ width: 100, height: 100 }}
                    />
                  </div> */}
                  <div style={{}}>
                    <p >
                     {data.route_name}
                    </p>
                  </div>
                </div>
              </>
              
              ),
              type: (
                <div style={{ color: "black" }}>
                  {data.transfer_type}
                </div>
              ),
              country: (
                
                <div style={{ color: "black" }}>
                  {data.country_name}
                </div>
              ),
              state: (
                <div style={{ color: "black" }}>
                  {data.state_name}
                </div>
              ),
              city: (
                <div style={{ color: "black" }}>
                  {data.city_name}
                </div>
              ),
             
              action: (
                <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                    <Link
                      to={`/editroute_pricing/${data.id}`}

                      className="btn btn-sm btn-soft-info ms-auto"
                      //   onClick={e => {
                      //     e.preventDefault()

                      //     const jobData = cellProps.row.original
                      //     // handleJobClick(jobData)
                      //   }}
                    >
                      <i className="mdi mdi-pencil-outline" id="edittooltip" />{" "}
                      Edit
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                 
                  {/* <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-danger ms-auto"
                      onClick={e => {
                        e.preventDefault()
                        openDeleteModal(data?.Product_Header?.id)
                       
                      }}
                    >
                      <i className="mdi mdi-delete-outline" id="edittooltip" />{" "}
                      Delete
                    </Link>
                  </li> */}
                 
                </ul>
              ),
              allData:
                data?.Product_Header?.id +
                data?.Product_Header?.product_name +
                data?.Product_Header?.product_code,
            })),
          ]

          setData(updatedData) // Update the state with the updated data array
        }
      })
      .catch(error => {
        // Handle the error
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false) // Set loading state to false after fetching data
      })
  }
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    console.log(scheduleData)
    const newData = scheduleData
      ? scheduleData.filter(item => item.id === productId)
      : []
    console.log(productId, newData)

    setFilteredData(newData)
  }, [productId])

  console.log(validation.values.productPricing.toString())
  document.title = "Tours | Flymiles"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeletePackage(selectedPkg)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Transfers" breadcrumbItem="View Routes" />
          <Row>
            <Col lg="12">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1"></h5>
                    <div className="flex-shrink-0">
                      <Link
                      
                        to="/addroute_pricing"
                        className="btn btn-primary me-1"
                      >
                        Add New Route
                      </Link>
                     
                    </div>
                  </div>    
            </Col>
          </Row>
          <TableContainer
            columns={columns}
            data={isLoading ? [] : data2}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Pricing & Scheduling
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                submitScheduleFormData()
                setLoading(true)
                return false
              }}
            >
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-productPricing-input42">
                      Select Pricing
                    </Label>
                    <select
                      className="form-select"
                      name="productPricing"
                      onChange={handleSelection}
                      value={selectedForm}
                    >
                      <option value="">Select Pricing </option>
                      <option value="Per Person">Per Person</option>
                      <option value="Per Vehicle">Per Vehicle</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-pax-input42">
                      Minimum No. of Pax
                    </Label>
                    <Input
                      name="pax"
                      type="Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.pax || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Your No. of Pax"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      Order Cut-off time
                    </Label>
                    <Input
                      name="cuttOffTime"
                      type="Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.cuttOffTime || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Order Cut-off time"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>When does your schedule start?</Label>
                    <Input
                      type="Date"
                      name="startDate"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Jun 19, 2024"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      When does your schedule end?
                    </Label>
                    <Input
                      type="Date"
                      name="endDate"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Your No. of Pax"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>Confirmation Type</Label>
                    <select
                      className="form-select"
                      name="confirmation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmation || ""}
                    >
                      <option>Select Confirmation Type</option>
                      <option value={1}>Automatic</option>
                      <option value={2}>Manually</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      Product Listing
                    </Label>
                    <Input
                      type="Number"
                      className="form-control"
                      name="productListing"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.productListing || ""}
                      placeholder="Enter Product Listing"
                      id="example-number-input"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {selectedForm === "Per Person" && (
                <>
                  <Row>
                    <Col lg="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="basicpill-email-input42">
                          Select Times:
                        </Label>
                        <MultiSelectTimes
                          selectedOptions={selectedTimes}
                          onChange={handleTimesChange}
                          value={validation.values.times || ""}
                          onBlur={validation.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup className="mb-3">
                        <div>
                          <Label htmlFor="basicpill-email-input42">
                            Pricing By
                          </Label>
                        </div>
                        <Label
                          htmlFor="example-number-input"
                          className="col-form-label"
                        >
                          Adult (12+ years):
                        </Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="priceAdult"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.priceAdult || ""}
                          placeholder="Enter Pricing"
                          id="example-number-input"
                        />
                        <Label
                          htmlFor="example-number-input"
                          className=" col-form-label"
                        >
                          Child (2-12 years):
                        </Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="priceChild"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.priceChild || ""}
                          placeholder="Enter Pricing"
                          id="example-number-input"
                        />
                        <Label
                          htmlFor="example-number-input"
                          className=" col-form-label"
                        >
                          Infant (0-2 years):
                        </Label>
                        <Input
                          className="form-control"
                          type="number"
                          name="priceInfant"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.priceInfant || ""}
                          placeholder="Enter Pricing"
                          id="example-number-input"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              {selectedForm === "Per Vehicle" && (
                <>
                  <Row>
                    <Col lg="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="basicpill-timegroup-input42">
                          Select Times :
                        </Label>
                        <TimeGroupBuilder
                          onChange={handleTimeGroupsChange}
                          initialValue={validation.values.timesGroup || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="basicpill-timegroup-input42">
                          Select Prices :
                        </Label>
                        <PriceGroupBuilder
                          onChange={handlePriceGroupsChange}
                          initialValue={validation.values.prices || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>Publish Online or Agent</Label>
                    <select
                      className="form-select"
                      name="publish"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.publish || ""}
                    >
                      <option>Select </option>
                      <option value={1}>Online</option>
                      <option value={2}>Agent</option>
                      <option value={3}>Both</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      Promotion Discount
                    </Label>
                    <Input
                      name="discount"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.discount || ""}
                      type="Number"
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Promotion Discount"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-cancel-input42">
                      Cancel Policy
                    </Label>
                    <Input
                      name="cancel"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.cancel || ""}
                      type="text"
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Cancel Policy"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>Popular Tour</Label>
                    <select
                      className="form-select"
                      name="popular"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.popular || ""}
                    >
                      <option>Select </option>
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-success save-user"
                    >
                      {loading ? "Loading..." : "Save"}
                    </button>
                  </div>
                </Col>
              </Row>
              {/*  */}
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}
Tours.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Tours
