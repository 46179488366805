import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"

function MyMultiSelectComponent({ onChange, id, data }) {
  const [cities, setCities] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const prevIdRef = useRef() // Use a ref to store the previous id

  // console.log(cities, selectedOption, data)
  useEffect(() => {
    const fetchCity = async () => {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/packages/get_city_list_specific_country.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ country_id: id ? id : null }),
        }
      )
      const json = await response.json()

      // Convert the API response to match react-select's expected format
      if (json) {
        const cityOptions = json.map(city => ({
          value: city.id.toString(), // Ensuring value is a string
          label: city.city,
        }))
        if (cityOptions.length === 0) {
          setCities([
            { value: "", label: "No city available", isDisabled: true },
          ])
        } else {
          setCities(cityOptions)
        }
      }
    }

    // Clear selectedOption only on id change (not on initial render)
    if (prevIdRef.current !== undefined && prevIdRef.current !== id) {
      setSelectedOption([])
    }

    fetchCity()

    // After everything, set prevIdRef to the current id for the next render
    prevIdRef.current = id
  }, [id])

  useEffect(() => {
    // This is only to set selectedOption based on initial data, not clear it
    if (data) {
      const selectedOptions = data.map(city => ({
        value: city.city_id.toString(), // Ensure value matches the expected format for react-select
        label: city.city_name,
      }))
      setSelectedOption(selectedOptions)
    }
  }, [data])

  return (
    <Select
      isMulti
      name="categories"
      options={cities}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={selectedOptions => {
        setSelectedOption(selectedOptions)
        onChange(selectedOptions) // Ensure this is handling the structure correctly for your use case
      }}
      value={selectedOption}
      placeholder="Select Cities"
      isOptionDisabled={option => option.isDisabled} // Disable the "No city available" option
    />
  )
}

export default MyMultiSelectComponent
