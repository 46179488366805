import React, { useState, useEffect } from "react"
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap"

const predefinedGroups = ["Mon,Tue,Wed,Thu", "Fri,Sat,Sun"]

const TimeGroupBuilder = ({ onChange, initialValue }) => {
  const [timeGroups, setTimeGroups] = useState([])
  const [newTime, setNewTime] = useState("")
  const [newGroup, setNewGroup] = useState("")

  useEffect(() => {
    if (
      initialValue &&
      initialValue.length > 0 &&
      Array.isArray(initialValue[0])
    ) {
      const formattedInitialValue = initialValue[0].map(item => ({
        time: item.pickup_time,
        group: item.group_type,
      }))
      setTimeGroups(formattedInitialValue)
    }
  }, [initialValue])

  const handleAdd = () => {
    if (newTime && newGroup) {
      const newTimeGroups = [...timeGroups, { time: newTime, group: newGroup }]
      setTimeGroups(newTimeGroups)
      onChange(newTimeGroups)
      setNewTime("")
      setNewGroup("")
    }
  }

  const handleRemove = index => {
    const newTimeGroups = [...timeGroups]
    newTimeGroups.splice(index, 1)
    setTimeGroups(newTimeGroups)
    onChange(newTimeGroups)
  }

  const groupedAndSortedTimes = () => {
    const grouped = timeGroups.reduce((acc, item) => {
      if (!acc[item.group]) {
        acc[item.group] = []
      }
      acc[item.group].push(item)
      return acc
    }, {})

    Object.keys(grouped).forEach(group => {
      grouped[group].sort((a, b) => a.time.localeCompare(b.time))
    })

    return grouped
  }

  const groupedTimes = groupedAndSortedTimes()

  return (
    <div>
      {Object.keys(groupedTimes).map(group => (
        <div key={group}>
          <h6>
            {"("}
            {group}
            {")"}
          </h6>

          <ul>
            {groupedTimes[group].map((item, index) => (
              <li key={index} className="mb-2">
                <span>{item.time}</span>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleRemove(timeGroups.indexOf(item))}
                  style={{ marginLeft: 20 }}
                  className="btn btn-sm btn-soft-danger"
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <Row className="mb-3">
        <Col md={5}>
          <FormGroup>
            <Label for="new-time">Time</Label>
            <Input
              type="time"
              id="new-time"
              value={newTime}
              onChange={e => setNewTime(e.target.value)}
              placeholder="Enter Time"
            />
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="new-group">Group</Label>
            <Input
              type="select"
              id="new-group"
              value={newGroup}
              onChange={e => setNewGroup(e.target.value)}
              placeholder="Select Group"
            >
              <option value="">Select Group</option>
              {predefinedGroups.map((group, groupIndex) => (
                <option key={groupIndex} value={group}>
                  {group}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={2} className="d-flex align-items-center mt-3">
          <Button color="primary" onClick={handleAdd}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default TimeGroupBuilder
