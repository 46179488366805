// import React, { useEffect, useState } from "react"
// import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

// const predefinedLanguages = [
//   {
//     id: "1",
//     language: "English",
//     inPerson: false,
//     audio: false,
//     written: false,
//   },
//   {
//     id: "2",
//     language: "Spanish",
//     inPerson: false,
//     audio: false,
//     written: false,
//   },
//   {
//     id: "3",
//     language: "French",
//     inPerson: false,
//     audio: false,
//     written: false,
//   },
//   {
//     id: "4",
//     language: "German",
//     inPerson: false,
//     audio: false,
//     written: false,
//   },
//   {
//     id: "5",
//     language: "Italian",
//     inPerson: false,
//     audio: false,
//     written: false,
//   },
// ]

// const GuideLanguagesForm = ({ onChange, dataLang, dataQues }) => {
//   const [languages, setLanguages] = useState([])
//   const [newLanguageText, setNewLanguageText] = useState("")
//   const [showNewLanguageInput, setShowNewLanguageInput] = useState(false)
//   const [guidesLicensed, setGuidesLicensed] = useState(false)
//   const [guidesServeAsDrivers, setGuidesServeAsDrivers] = useState(false)
//   const [offers, setOffers] = useState(false)
//   // console.log(dataLang, dataQues)
//   useEffect(() => {
//     if (dataLang) {
//       const mergeLanguages = (predefinedItems, incomingItems) => {
//         const merged = predefinedItems.map(pre => {
//           const found = incomingItems.find(
//             item => item.language === pre.language
//           )
//           return found
//             ? {
//                 ...pre,
//                 inPerson: found.inperson === "yes",
//                 audio: found.audio === "yes",
//                 written: found.written === "yes",
//               }
//             : pre
//         })
//         const additionalItems = incomingItems.filter(
//           item => !predefinedItems.find(pre => pre.language === item.language)
//         )
//         return merged.concat(
//           additionalItems.map(item => ({
//             id: item.id,
//             language: item.language,
//             inPerson: item.inperson === "yes",
//             audio: item.audio === "yes",
//             written: item.written === "yes",
//           }))
//         )
//       }

//       setLanguages(mergeLanguages(predefinedLanguages, dataLang))
//     } else {
//       setLanguages(predefinedLanguages)
//     }

//     if (dataQues) {
//       dataQues.forEach(question => {
//         if (question.question === "Are your guides licensed/certified?") {
//           setGuidesLicensed(question.answer === "yes")
//         }
//         if (question.question === "Do your guides also serve as drivers?") {
//           setGuidesServeAsDrivers(question.answer === "yes")
//         }
//         if (
//           question.question ===
//           "Do you offer in-person, audio or written guides?"
//         ) {
//           setOffers(question.answer === "yes")
//         }
//       })
//     }
//   }, [dataLang, dataQues])

//   const handleLanguageChange = (id, type, checked) => {
//     setLanguages(current =>
//       current.map(language =>
//         language.id === id ? { ...language, [type]: checked } : language
//       )
//     )
//   }

//   const handleAddNewLanguage = () => {
//     if (newLanguageText.trim()) {
//       const newId = `new-${languages.length + 1}`
//       setLanguages(current => [
//         ...current,
//         {
//           id: newId,
//           language: newLanguageText,
//           inPerson: false,
//           audio: false,
//           written: false,
//         },
//       ])
//       setNewLanguageText("")
//       setShowNewLanguageInput(false)
//     }
//   }

//   useEffect(() => {
//     onChange({ languages, guidesLicensed, guidesServeAsDrivers, offers })
//   }, [languages, guidesLicensed, guidesServeAsDrivers, offers, onChange])

//   return (
//     <TabPane tabId="guideLanguages">
//       <h3>What type of guide is included?</h3>
//       <div className="table-responsive">
//         <table className="table table-bordered">
//           <thead>
//             <tr>
//               <th>Languages</th>
//               <th>In-person</th>
//               <th>Audio</th>
//               <th>Written</th>
//             </tr>
//           </thead>
//           <tbody>
//             {languages.map(language => (
//               <tr key={language.id}>
//                 <td>{language.language}</td>
//                 <td>
//                   <Input
//                     type="checkbox"
//                     checked={language.inPerson === true ? true : false}
//                     // checked={item.included === true ? true : false}

//                     onChange={e =>
//                       handleLanguageChange(
//                         language.id,
//                         "inPerson",
//                         e.target.checked
//                       )
//                     }
//                     onClick={e => {
//                       e.preventDefault()
//                       if (language.inPerson === false) {
//                         handleLanguageChange(
//                           language.id,
//                           "inPerson",
//                           (e.target.checked = true)
//                         )
//                       } else {
//                         handleLanguageChange(
//                           language.id,
//                           "inPerson",
//                           (e.target.checked = false)
//                         )
//                       }
//                     }}
//                   />
//                 </td>
//                 <td>
//                   <Input
//                     type="checkbox"
//                     checked={language.audio === true ? true : false}
//                     // checked={item.included === true ? true : false}

//                     onChange={e =>
//                       handleLanguageChange(
//                         language.id,
//                         "audio",
//                         e.target.checked
//                       )
//                     }
//                     onClick={e => {
//                       e.preventDefault()
//                       if (language.audio === false) {
//                         handleLanguageChange(
//                           language.id,
//                           "audio",
//                           (e.target.checked = true)
//                         )
//                       } else {
//                         handleLanguageChange(
//                           language.id,
//                           "audio",
//                           (e.target.checked = false)
//                         )
//                       }
//                     }}
//                   />
//                 </td>
//                 <td>
//                   <Input
//                     type="checkbox"
//                     checked={language.written === true ? true : false}
//                     // checked={item.included === true ? true : false}

//                     onChange={e =>
//                       handleLanguageChange(
//                         language.id,
//                         "written",
//                         e.target.checked
//                       )
//                     }
//                     onClick={e => {
//                       e.preventDefault()
//                       if (language.written === false) {
//                         handleLanguageChange(
//                           language.id,
//                           "written",
//                           (e.target.checked = true)
//                         )
//                       } else {
//                         handleLanguageChange(
//                           language.id,
//                           "written",
//                           (e.target.checked = false)
//                         )
//                       }
//                     }}
//                   />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       {showNewLanguageInput ? (
//         <>
//           <FormGroup>
//             <Label>New Language:</Label>
//             <Input
//               type="text"
//               value={newLanguageText}
//               onChange={e => setNewLanguageText(e.target.value)}
//               placeholder="Enter a new language"
//             />
//           </FormGroup>
//           <Button color="success" onClick={handleAddNewLanguage}>
//             Add
//           </Button>
//         </>
//       ) : (
//         <Button color="primary" onClick={() => setShowNewLanguageInput(true)}>
//           + Add Another Language
//         </Button>
//       )}
//       <br />
//       <FormGroup>
//         <Label style={{ marginTop: 20 }}>
//           Do you offer in-person, audio or written guides?
//         </Label>
//         <div>
//           <Input
//             type="radio"
//             name="offers"
//             // value="yes"
//             checked={offers === true ? true : false}
//             onChange={() => setOffers(true)}
//             onClick={e => {
//               e.preventDefault()
//               // checked
//               setOffers(true)
//             }}
//           />{" "}
//           Yes
//           <Input
//             type="radio"
//             name="offers"
//             checked={offers === false ? true : false}
//             onChange={() => setOffers(false)}
//             onClick={e => {
//               e.preventDefault()
//               // checked
//               setOffers(false)
//             }}
//             style={{ marginLeft: 20 }}
//           />{" "}
//           No
//         </div>
//       </FormGroup>
//       <FormGroup>
//         <Label>Are your guides licensed/certified?</Label>
//         <div>
//           <Input
//             type="radio"
//             name="guidesLicensed"
//             // value="yes"
//             checked={guidesLicensed === true ? true : false}
//             onChange={() => setGuidesLicensed(true)}
//             onClick={e => {
//               e.preventDefault()
//               // checked
//               setGuidesLicensed(true)
//             }}
//           />{" "}
//           Yes
//           <Input
//             type="radio"
//             name="guidesLicensed"
//             checked={guidesLicensed === false ? true : false}
//             onChange={() => setGuidesLicensed(false)}
//             onClick={e => {
//               e.preventDefault()
//               // checked
//               setGuidesLicensed(false)
//             }}
//             style={{ marginLeft: 20 }}
//           />{" "}
//           No
//         </div>
//       </FormGroup>
//       <FormGroup>
//         <Label>Do your guides also serve as drivers?</Label>
//         <div>
//           <Input
//             type="radio"
//             name="guidesServeAsDrivers"
//             checked={guidesServeAsDrivers === true ? true : false}
//             onChange={() => setGuidesServeAsDrivers(true)}
//             onClick={e => {
//               e.preventDefault()
//               // checked
//               setGuidesServeAsDrivers(true)
//             }}
//           />{" "}
//           Yes
//           <Input
//             type="radio"
//             name="guidesServeAsDrivers"
//             checked={guidesServeAsDrivers === false ? true : false}
//             onChange={() => setGuidesServeAsDrivers(false)}
//             onClick={e => {
//               e.preventDefault()
//               // checked
//               setGuidesServeAsDrivers(false)
//             }}
//             style={{ marginLeft: 20 }}
//           />{" "}
//           No
//         </div>
{
  /* <Button
color="danger"
size="sm"
onClick={handleResetGuidesServeAsDrivers}
style={{ marginLeft: "10px" }}
>
Reset
</Button> */
}
//       </FormGroup>
//     </TabPane>
//   )
// }

// export default GuideLanguagesForm

import React, { useEffect, useState } from "react"
import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

const predefinedLanguages = [
  {
    id: "1",
    language: "English",
    inPerson: false,
    audio: false,
    written: false,
  },
  {
    id: "2",
    language: "Spanish",
    inPerson: false,
    audio: false,
    written: false,
  },
  {
    id: "3",
    language: "French",
    inPerson: false,
    audio: false,
    written: false,
  },
  {
    id: "4",
    language: "German",
    inPerson: false,
    audio: false,
    written: false,
  },
  {
    id: "5",
    language: "Italian",
    inPerson: false,
    audio: false,
    written: false,
  },
]

const GuideLanguagesForm = ({ onChange, dataLang, dataQues }) => {
  const [languages, setLanguages] = useState([])
  const [newLanguageText, setNewLanguageText] = useState("")
  const [showNewLanguageInput, setShowNewLanguageInput] = useState(false)
  const [guidesLicensed, setGuidesLicensed] = useState("")
  const [guidesServeAsDrivers, setGuidesServeAsDrivers] = useState("")
  const [offers, setOffers] = useState("")

  useEffect(() => {
    console.log(dataQues)

    if (dataLang) {
      const mergeLanguages = (predefinedItems, incomingItems) => {
        const merged = predefinedItems.map(pre => {
          const found = incomingItems.find(
            item => item.language === pre.language
          )
          return found
            ? {
                ...pre,
                inPerson: found.inperson === "yes",
                audio: found.audio === "yes",
                written: found.written === "yes",
              }
            : pre
        })
        const additionalItems = incomingItems.filter(
          item => !predefinedItems.find(pre => pre.language === item.language)
        )
        return merged.concat(
          additionalItems.map(item => ({
            id: item.id,
            language: item.language,
            inPerson: item.inperson === "yes",
            audio: item.audio === "yes",
            written: item.written === "yes",
          }))
        )
      }

      setLanguages(mergeLanguages(predefinedLanguages, dataLang))
    } else {
      setLanguages(predefinedLanguages)
    }

    if (dataQues) {
      dataQues.forEach(question => {
        if (question.question === "Are your guides licensed/certified?") {
          setGuidesLicensed(
            question.answer === "yes"
              ? true
              : question.answer === "no"
              ? false
              : ""
          )
        }
        if (question.question === "Do your guides also serve as drivers?") {
          setGuidesServeAsDrivers(
            question.answer === "yes"
              ? true
              : question.answer === "no"
              ? false
              : ""
          )
        }
        if (
          question.question ===
          "Do you offer in-person, audio or written guides?"
        ) {
          setOffers(
            question.answer === "yes"
              ? true
              : question.answer === "no"
              ? false
              : ""
          )
        }
      })
    }
  }, [dataLang, dataQues])
  // console.log(guidesServeAsDrivers, guidesLicensed, offers)

  const handleLanguageChange = (id, type, checked) => {
    setLanguages(current =>
      current.map(language =>
        language.id === id ? { ...language, [type]: checked } : language
      )
    )
  }

  const handleAddNewLanguage = () => {
    if (newLanguageText.trim()) {
      const newId = `new-${languages.length + 1}`
      setLanguages(current => [
        ...current,
        {
          id: newId,
          language: newLanguageText,
          inPerson: false,
          audio: false,
          written: false,
        },
      ])
      setNewLanguageText("")
      setShowNewLanguageInput(false)
    }
  }

  // Individual reset handlers to set the values to an empty string
  const handleResetGuidesLicensed = () => {
    setGuidesLicensed("")
  }

  const handleResetGuidesServeAsDrivers = () => {
    setGuidesServeAsDrivers("")
  }

  const handleResetOffers = () => {
    setOffers("")
  }

  useEffect(() => {
    onChange({ languages, guidesLicensed, guidesServeAsDrivers, offers })
  }, [languages, guidesLicensed, guidesServeAsDrivers, offers, onChange])

  return (
    <TabPane tabId="guideLanguages">
      <h3>What type of guide is included?</h3>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Languages</th>
              <th>In-person</th>
              <th>Audio</th>
              <th>Written</th>
            </tr>
          </thead>
          <tbody>
            {languages.map(language => (
              <tr key={language.id}>
                <td>{language.language}</td>
                <td>
                  <Input
                    type="checkbox"
                    checked={language.inPerson === true ? true : false}
                    onChange={e =>
                      handleLanguageChange(
                        language.id,
                        "inPerson",
                        e.target.checked
                      )
                    }
                    onClick={e => {
                      e.preventDefault()
                      if (language.inPerson === false) {
                        handleLanguageChange(
                          language.id,
                          "inPerson",
                          (e.target.checked = true)
                        )
                      } else {
                        handleLanguageChange(
                          language.id,
                          "inPerson",
                          (e.target.checked = false)
                        )
                      }
                    }}
                  />
                </td>
                <td>
                  <Input
                    type="checkbox"
                    checked={language.audio === true ? true : false}
                    onChange={e =>
                      handleLanguageChange(
                        language.id,
                        "audio",
                        e.target.checked
                      )
                    }
                    onClick={e => {
                      e.preventDefault()
                      if (language.audio === false) {
                        handleLanguageChange(
                          language.id,
                          "audio",
                          (e.target.checked = true)
                        )
                      } else {
                        handleLanguageChange(
                          language.id,
                          "audio",
                          (e.target.checked = false)
                        )
                      }
                    }}
                  />
                </td>
                <td>
                  <Input
                    type="checkbox"
                    checked={language.written === true ? true : false}
                    onChange={e =>
                      handleLanguageChange(
                        language.id,
                        "written",
                        e.target.checked
                      )
                    }
                    onClick={e => {
                      e.preventDefault()
                      if (language.written === false) {
                        handleLanguageChange(
                          language.id,
                          "written",
                          (e.target.checked = true)
                        )
                      } else {
                        handleLanguageChange(
                          language.id,
                          "written",
                          (e.target.checked = false)
                        )
                      }
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showNewLanguageInput ? (
        <>
          <FormGroup>
            <Label>New Language:</Label>
            <Input
              type="text"
              value={newLanguageText}
              onChange={e => setNewLanguageText(e.target.value)}
              placeholder="Enter a new language"
            />
          </FormGroup>
          <Button color="success" onClick={handleAddNewLanguage}>
            Add
          </Button>
        </>
      ) : (
        <Button color="primary" onClick={() => setShowNewLanguageInput(true)}>
          + Add Another Language
        </Button>
      )}
      <br />

      <FormGroup>
        <Label>Do you offer in-person, audio or written guides?</Label>
        <div>
          <Input
            type="radio"
            name="offers"
            checked={offers === true ? true : offers === false ? false : ""}
            onChange={() => setOffers(true)}
            onClick={e => {
              e.preventDefault()
              // checked
              setOffers(true)
            }}
          />{" "}
          Yes
          <Input
            type="radio"
            name="offers"
            checked={offers === false ? true : offers === true ? false : ""}
            onChange={() => setOffers(false)}
            onClick={e => {
              e.preventDefault()
              // checked
              setOffers(false)
            }}
            style={{ marginLeft: 20 }}
          />{" "}
          No
          <Button
            color="btn btn-sm btn-soft-danger ms-5"
            size="sm"
            onClick={handleResetOffers}
            style={{ marginLeft: "10px" }}
          >
            <i className="mdi mdi-delete-outline" id="deletetooltip" />
          </Button>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Are your guides licensed/certified?</Label>
        <div>
          <Input
            type="radio"
            name="guidesLicensed"
            checked={
              guidesLicensed === true
                ? true
                : guidesLicensed === false
                ? false
                : ""
            }
            onChange={() => setGuidesLicensed(true)}
            onClick={e => {
              e.preventDefault()
              // checked
              setGuidesLicensed(true)
            }}
          />{" "}
          Yes
          <Input
            type="radio"
            name="guidesLicensed"
            checked={
              guidesLicensed === false
                ? true
                : guidesLicensed === true
                ? false
                : ""
            }
            onChange={() => setGuidesLicensed(false)}
            onClick={e => {
              e.preventDefault()
              // checked
              setGuidesLicensed(false)
            }}
            style={{ marginLeft: 20 }}
          />{" "}
          No
          <Button
            color="btn btn-sm btn-soft-danger ms-5"
            size="sm"
            onClick={handleResetGuidesLicensed}
            style={{ marginLeft: "10px" }}
          >
            <i className="mdi mdi-delete-outline" id="deletetooltip" />
          </Button>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Do your guides also serve as drivers?</Label>
        <div>
          <Input
            type="radio"
            name="guidesServeAsDrivers"
            checked={
              guidesServeAsDrivers === true
                ? true
                : guidesServeAsDrivers === false
                ? false
                : ""
            }
            onChange={() => setGuidesServeAsDrivers(true)}
            onClick={e => {
              e.preventDefault()
              // checked
              setGuidesServeAsDrivers(true)
            }}
          />{" "}
          Yes
          <Input
            type="radio"
            name="guidesServeAsDrivers"
            checked={
              guidesServeAsDrivers === false
                ? true
                : guidesServeAsDrivers === true
                ? false
                : ""
            }
            onChange={() => setGuidesServeAsDrivers(false)}
            onClick={e => {
              e.preventDefault()
              // checked
              setGuidesServeAsDrivers(false)
            }}
            style={{ marginLeft: 20 }}
          />{" "}
          No
          <Button
            color="btn btn-sm btn-soft-danger ms-5"
            size="sm"
            onClick={handleResetGuidesServeAsDrivers}
            style={{ marginLeft: "10px" }}
          >
            <i className="mdi mdi-delete-outline" id="deletetooltip" />
          </Button>
        </div>
      </FormGroup>
    </TabPane>
  )
}

export default GuideLanguagesForm
