// // import React, { useState } from "react"
// // import {
// //   TabPane,
// //   FormGroup,
// //   Label,
// //   Input,
// //   Button,
// //   ListGroup,
// //   ListGroupItem,
// //   Row,
// //   Col,
// // } from "reactstrap"

// // const ItineraryItem = ({ item, dayNumber, onEdit, onDelete }) => (
// //   <Row style={{ borderBottom: "1px solid #ddd", marginBottom: 10 }}>
// //     <Col lg="10">
// //       <div>
// //         <h4>Day - {dayNumber}</h4>
// //         <h5>{item.header}</h5>
// //         <p>{item.body}</p>
// //         <div style={{ display: "flex", gap: "30px" }}>
// //           {item.duration && <p>Duration: {item.duration}</p>}
// //           {item.includes && <p>Includes: {item.includes}</p>}
// //         </div>
// //       </div>
// //     </Col>
// //     <Col
// //       lg="2"
// //       style={{
// //         display: "flex",
// //         flexDirection: "row",
// //         gap: 10,
// //         justifyContent: "flex-end",
// //         alignItems: "flex-start",
// //       }}
// //     >
// //       <Button
// //         color="success"
// //         onClick={() => onEdit(item.id)}
// //         style={{ marginBottom: "10px", flexWrap: "wrap" }}
// //       >
// //         Edit
// //       </Button>
// //       <Button
// //         color="danger"
// //         onClick={() => onDelete(item.id)}
// //         style={{ marginBottom: "10px", flexWrap: "wrap" }}
// //       >
// //         Delete
// //       </Button>
// //     </Col>
// //   </Row>
// // )

// // const ItineraryDayComponent = () => {
// //   const [itinerary, setItinerary] = useState([
// //     // Initial example items, which you might load from an API or prop
// // {
// //   id: 1,
// //   header: "Sydney Harbour Bridge, Sydney, New South Wales 2060 Australia",
// //   body: "Pickup from your Hotel in Sydney CBD and around and then drive over the famous...",
// //   duration: "2 hours",
// //   includes: "Admission included",
// // },
// // {
// //   id: 2,
// //   header: "Blue Mountains National Park, New South Wales",
// //   body: "Explore the natural wonders of the Blue Mountains, enjoy scenic views from the Three Sisters lookout, and take a ride on the Katoomba Scenic Railway.",
// //   duration: "Full day",
// //   includes: "Guided tour, transportation, and park entry fees",
// // },
// // {
// //   id: 3,
// //   header: "Bondi Beach, Sydney, New South Wales",
// //   body: "Relax on the famous Bondi Beach, try out some surfing, or enjoy a coastal walk for some fresh sea air and stunning ocean views.",
// //   duration: "Half day",
// //   includes: "Beach access; surfboard rental available at additional cost",
// // },
// // {
// //   id: 4,
// //   header: "Taronga Zoo, Sydney, New South Wales",
// //   body: "Visit the iconic Taronga Zoo, home to thousands of exquisite animals, including native Australian wildlife and rare and endangered species.",
// //   duration: "3-4 hours",
// //   includes: "Zoo admission, cable car ride, and seal show",
// // },
// //   ])
// //   const [newItem, setNewItem] = useState({
// //     header: "",
// //     body: "",
// //     duration: "",
// //     includes: "",
// //   })
// //   const [showForm, setShowForm] = useState(false)
// //   const [currentItem, setCurrentItem] = useState(null)

// //   const handleInputChange = e => {
// //     const { name, value } = e.target
// //     setNewItem({ ...newItem, [name]: value })
// //   }

// //   const addItineraryItem = () => {
// //     if (newItem.header.trim() && newItem.body.trim()) {
// //       setItinerary([...itinerary, { ...newItem, id: itinerary.length + 1 }])
// //       setNewItem({ header: "", body: "", duration: "", includes: "" }) // Reset the form
// //       setShowForm(false) // Hide the form after adding the item
// //     } else {
// //       alert("Please fill in at least the header and body for the new item.")
// //     }
// //   }

// //   // Function to handle editing an itinerary item
// //   const handleEditItem = id => {
// //     const itemToEdit = itinerary.find(item => item.id === id)
// //     setCurrentItem(itemToEdit)
// //     setShowForm(true) // Show the form to allow user to edit the details
// //   }

// //   // Function to handle deleting an itinerary item
// //   const handleDeleteItem = id => {
// //     // Remove the item from the itinerary
// //     const updatedItinerary = itinerary.filter(item => item.id !== id)
// //     setItinerary(updatedItinerary)
// //   }
// //   const nextDayNumber = itinerary.length + 1

// //   const renderForm = () => (
// //     <>
// //       <FormGroup>
// //         <h2>Day - {nextDayNumber}</h2>
// //         <Label for="header">Header:</Label>
// //         <Input
// //           id="header"
// //           name="header"
// //           value={newItem.header}
// //           onChange={handleInputChange}
// //         />
// //       </FormGroup>
// //       <FormGroup>
// //         <Label for="body">Body:</Label>
// //         <Input
// //           id="body"
// //           name="body"
// //           value={newItem.body}
// //           onChange={handleInputChange}
// //         />
// //       </FormGroup>
// //       <FormGroup>
// //         <Label for="duration">Duration:</Label>
// //         <Input
// //           id="duration"
// //           name="duration"
// //           value={newItem.duration}
// //           onChange={handleInputChange}
// //         />
// //       </FormGroup>
// //       <FormGroup>
// //         <Label for="includes">Includes:</Label>
// //         <Input
// //           id="includes"
// //           name="includes"
// //           value={newItem.includes}
// //           onChange={handleInputChange}
// //         />
// //       </FormGroup>
// //       <Button color="success" onClick={addItineraryItem}>
// //         Save Day
// //       </Button>
// //     </>
// //   )

// //   return (
// //     <TabPane tabId={3}>
// //       <ListGroup>
// //         {itinerary.map((item, index) => (
// //           <ItineraryItem
// //             key={index}
// //             item={item}
// //             dayNumber={index + 1}
// //             onEdit={handleEditItem}
// //             onDelete={handleDeleteItem}
// //           />
// //         ))}
// //       </ListGroup>
// //       {showForm ? (
// //         renderForm()
// //       ) : (
// //         <Button color="primary" onClick={() => setShowForm(true)}>
// //           Add New Day
// //         </Button>
// //       )}
// //     </TabPane>
// //   )
// // }

// // export default ItineraryDayComponent

// import React, { useState, useEffect } from "react"
// import {
//   TabPane,
//   FormGroup,
//   Label,
//   Input,
//   Button,
//   ListGroup,
//   Row,
//   Col,
// } from "reactstrap"

// const ItineraryItem = ({ item, dayNumber, onEdit, onDelete }) => (
//   <Row style={{ borderBottom: "1px solid #ddd", marginBottom: 10 }}>
//     <Col lg="10">
//       <div>
//         <h4>Day - {dayNumber}</h4>
//         <h5>{item.header}</h5>
//         <p>{item.body}</p>
//         <div style={{ display: "flex", gap: "30px" }}>
//           {item.duration && <p>Duration: {item.duration}</p>}
//           {item.includes && <p>Includes: {item.includes}</p>}
//         </div>
//       </div>
//     </Col>
//     <Col
//       lg="2"
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "flex-end",
//         gap: 10,
//         alignItems: "flex-start",
//       }}
//     >
//       <Button color="success" onClick={() => onEdit(item.id)}>
//         Edit
//       </Button>
//       <Button color="danger" onClick={() => onDelete(item.id)}>
//         Delete
//       </Button>
//     </Col>
//   </Row>
// )

// const ItineraryDayComponent = ({ onChange, data }) => {
//   const [itinerary, setItinerary] = useState([
//     {
//       id: 1,
//       header: "Sydney Harbour Bridge, Sydney, New South Wales 2060 Australia",
//       body: "Pickup from your Hotel in Sydney CBD and around and then drive over the famous...",
//       duration: "2 hours",
//       includes: "Admission included",
//     },
//     {
//       id: 2,
//       header: "Blue Mountains National Park, New South Wales",
//       body: "Explore the natural wonders of the Blue Mountains, enjoy scenic views from the Three Sisters lookout, and take a ride on the Katoomba Scenic Railway.",
//       duration: "Full day",
//       includes: "Guided tour, transportation, and park entry fees",
//     },
//     {
//       id: 3,
//       header: "Bondi Beach, Sydney, New South Wales",
//       body: "Relax on the famous Bondi Beach, try out some surfing, or enjoy a coastal walk for some fresh sea air and stunning ocean views.",
//       duration: "Half day",
//       includes: "Beach access; surfboard rental available at additional cost",
//     },
//     {
//       id: 4,
//       header: "Taronga Zoo, Sydney, New South Wales",
//       body: "Visit the iconic Taronga Zoo, home to thousands of exquisite animals, including native Australian wildlife and rare and endangered species.",
//       duration: "3-4 hours",
//       includes: "Zoo admission, cable car ride, and seal show",
//     },
//   ])
//   const [newItem, setNewItem] = useState({
//     id: null, // Use null to indicate a new item
//     header: "",
//     body: "",
//     duration: "",
//     includes: "",
//   })
//   const [showForm, setShowForm] = useState(false)
//   const [isEditing, setIsEditing] = useState(false)

//   const handleInputChange = e => {
//     const { name, value } = e.target
//     setNewItem({ ...newItem, [name]: value })
//   }

//   const addOrEditItineraryItem = () => {
//     if (newItem.header.trim() && newItem.body.trim()) {
//       if (isEditing) {
//         // If we're editing, update the existing item
//         setItinerary(
//           itinerary.map(item => (item.id === newItem.id ? newItem : item))
//         )
//       } else {
//         // If we're adding, append the new item to the list
//         setItinerary([...itinerary, { ...newItem, id: itinerary.length + 1 }])
//       }
//       // Reset the form and close it
//       setNewItem({ id: null, header: "", body: "", duration: "", includes: "" })
//       setShowForm(false)
//       setIsEditing(false)
//     } else {
//       alert("Please fill in at least the header and body for the new item.")
//     }
//   }

//   const handleEditItem = id => {
//     const itemToEdit = itinerary.find(item => item.id === id)
//     setNewItem(itemToEdit)
//     setShowForm(true)
//     setIsEditing(true)
//   }

//   const handleDeleteItem = id => {
//     setItinerary(itinerary.filter(item => item.id !== id))
//   }
//   useEffect(() => {
//     onChange(itinerary)
//   }, [
//     handleInputChange,
//     handleEditItem,
//     addOrEditItineraryItem,
//     handleDeleteItem,
//   ])

//   const renderForm = () => (
//     <>
//       <FormGroup>
//         <h2>Day - {isEditing ? newItem.id : itinerary.length + 1}</h2>
//         <Label for="header">Header:</Label>
//         <Input
//           id="header"
//           name="header"
//           value={newItem.header}
//           onChange={handleInputChange}
//         />
//       </FormGroup>
//       <FormGroup>
//         <Label for="body">Body:</Label>
//         <Input
//           id="body"
//           name="body"
//           value={newItem.body}
//           onChange={handleInputChange}
//         />
//       </FormGroup>
//       <FormGroup>
//         <Label for="duration">Duration:</Label>
//         <Input
//           id="duration"
//           name="duration"
//           value={newItem.duration}
//           onChange={handleInputChange}
//         />
//       </FormGroup>
//       <FormGroup>
//         <Label for="includes">Includes:</Label>
//         <Input
//           id="includes"
//           name="includes"
//           value={newItem.includes}
//           onChange={handleInputChange}
//         />
//       </FormGroup>
//       <Button color="success" onClick={addOrEditItineraryItem}>
//         {isEditing ? "Save Changes" : "Save Day"}
//       </Button>
//     </>
//   )

//   return (
//     <TabPane tabId={3}>
//       <ListGroup>
//         {itinerary.map((item, index) => (
//           <ItineraryItem
//             key={item.id}
//             item={item}
//             dayNumber={index + 1}
//             onEdit={handleEditItem}
//             onDelete={handleDeleteItem}
//           />
//         ))}
//       </ListGroup>
//       {showForm ? (
//         renderForm()
//       ) : (
//         <Button
//           color="primary"
//           onClick={() => {
//             setShowForm(true)
//             setIsEditing(false)
//           }}
//         >
//           Add New Day
//         </Button>
//       )}
//     </TabPane>
//   )
// }

// export default ItineraryDayComponent

import React, { useState, useEffect } from "react"
import {
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  Row,
  Col,
} from "reactstrap"

const ItineraryItem = ({ item, dayNumber, onEdit, onDelete }) => (
  <Row style={{ borderBottom: "1px solid #ddd", marginBottom: 10 }}>
    <Col lg="10">
      <div>
        {/* <h4>Day - {dayNumber}</h4> */}
        <h5>{item.header}</h5>
        <p>{item.body}</p>
        <div style={{ display: "flex", gap: "30px" }}>
          {item.duration && <p>Duration: {item.duration}</p>}
          {item.includes && <p>Includes: {item.includes}</p>}
        </div>
      </div>
    </Col>
    <Col
      lg="2"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: 10,
        alignItems: "flex-start",
      }}
    >
      <Button color="success" onClick={() => onEdit(item.id)}>
        Edit
      </Button>
      <Button color="danger" onClick={() => onDelete(item.id)}>
        Delete
      </Button>
    </Col>
  </Row>
)

const predefinedItineraries = [
  // {
  //   id: "pre-1",
  //   header: "Sydney Harbour Bridge, Sydney, New South Wales 2060 Australia",
  //   body: "Pickup from your Hotel in Sydney CBD and around and then drive over the famous...",
  //   duration: "2 hours",
  //   includes: "Admission included",
  // },
  // {
  //   id: "pre-2",
  //   header: "Blue Mountains National Park, New South Wales",
  //   body: "Explore the natural wonders of the Blue Mountains, enjoy scenic views from the Three Sisters lookout, and take a ride on the Katoomba Scenic Railway.",
  //   duration: "Full day",
  //   includes: "Guided tour, transportation, and park entry fees",
  // },
  // {
  //   id: "pre-3",
  //   header: "Bondi Beach, Sydney, New South Wales",
  //   body: "Relax on the famous Bondi Beach, try out some surfing, or enjoy a coastal walk for some fresh sea air and stunning ocean views.",
  //   duration: "Half day",
  //   includes: "Beach access; surfboard rental available at additional cost",
  // },
  // {
  //   id: "pre-4",
  //   header: "Taronga Zoo, Sydney, New South Wales",
  //   body: "Visit the iconic Taronga Zoo, home to thousands of exquisite animals, including native Australian wildlife and rare and endangered species.",
  //   duration: "3-4 hours",
  //   includes: "Zoo admission, cable car ride, and seal show",
  // },
]

const ItineraryDayComponent = ({ onChange, data }) => {
  const [itinerary, setItinerary] = useState([])
  const [newItem, setNewItem] = useState({
    id: null,
    header: "",
    body: "",
    duration: "",
    includes: "",
  })

  const [showForm, setShowForm] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  // Merge predefined itineraries with incoming data, prioritizing incoming data
  useEffect(() => {
    const combinedItineraries = [...predefinedItineraries]
    data?.forEach(d => {
      const index = combinedItineraries.findIndex(it => it.id === d.id)
      const newItem = {
        ...d,
        id: `data-${d.id}`,
        header: d.header || "",
        body: d.body || "",
        duration: d.duration || "",
        includes: d.include || "", // Ensure includes has a default value
      }

      if (index > -1) {
        combinedItineraries[index] = {
          ...combinedItineraries[index],
          ...newItem,
        }
      } else {
        combinedItineraries.push(newItem)
      }
    })
    setItinerary(combinedItineraries)
  }, [data])

  // Initialize component with existing data if provided
  useEffect(() => {
    if (data && data.length > 0) {
      const initializedData = data.map(d => ({
        ...d,
        header: d.header || "",
        body: d.body || "",
        duration: d.duration || "",
        includes: d.include || "", // Map include to includes
      }))
      setItinerary(initializedData)
    }
  }, [data])

  useEffect(() => {
    onChange(itinerary)
  }, [itinerary, onChange])

  const handleInputChange = e => {
    const { name, value } = e.target
    setNewItem({ ...newItem, [name]: value })
  }

  const addOrEditItineraryItem = () => {
    if (newItem.header.trim() && newItem.body.trim()) {
      if (isEditing) {
        setItinerary(
          itinerary.map(item =>
            item.id === newItem.id ? { ...item, ...newItem } : item
          )
        )
      } else {
        setItinerary([...itinerary, { ...newItem, id: Date.now().toString() }])
      }
      setNewItem({ id: null, header: "", body: "", duration: "", includes: "" })
      setShowForm(false)
      setIsEditing(false)
    } else {
      alert("Please fill in at least the header and body for the new item.")
    }
  }

  const handleEditItem = id => {
    const itemToEdit = itinerary.find(item => item.id === id)
    setNewItem(itemToEdit)
    setShowForm(true)
    setIsEditing(true)
  }

  const handleDeleteItem = id => {
    setItinerary(itinerary.filter(item => item.id !== id))
  }

  const renderForm = () => (
    <>
      <FormGroup>
        {/* <h2>Day - {isEditing ? "Edit" : `Add New Day`}</h2> */}
        <Label for="header">Header:</Label>
        <Input
          id="header"
          name="header"
          value={newItem.header}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="body">Body:</Label>
        <Input
          type="textarea"
          id="body"
          name="body"
          value={newItem.body}
          onChange={handleInputChange}
          rows={4}
        />
      </FormGroup>
      <FormGroup>
        <Label for="duration">Duration:</Label>
        <Input
          id="duration"
          name="duration"
          value={newItem.duration}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="includes">Includes:</Label>
        <Input
          id="includes"
          name="includes"
          value={newItem.includes}
          onChange={handleInputChange}
        />
      </FormGroup>
      <Button color="success" onClick={addOrEditItineraryItem}>
        {isEditing ? "Save Changes" : "Add Day"}
      </Button>
    </>
  )

  return (
    <TabPane tabId="itinerary">
      <ListGroup>
        {itinerary.map((item, index) => (
          <ItineraryItem
            key={item.id}
            item={item}
            dayNumber={index + 1}
            onEdit={handleEditItem}
            onDelete={handleDeleteItem}
          />
        ))}
      </ListGroup>
      {showForm ? (
        renderForm()
      ) : (
        <Button
          color="primary"
          onClick={() => {
            setShowForm(true)
            setIsEditing(false)
          }}
        >
          Add New Day
        </Button>
      )}
    </TabPane>
  )
}

export default ItineraryDayComponent
