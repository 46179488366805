import React, { useState, useEffect } from "react"
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap"

const predefinedGroups = ["Mon,Tue,Wed,Thu", "Fri,Sat,Sun"]
const personTypes = ["Adult", "Child", "Infant"]

const PriceGroupBuilder = ({ onChange, initialValue }) => {
  const [priceGroups, setPriceGroups] = useState([])
  const [newPersonType, setNewPersonType] = useState("")
  const [newGroup, setNewGroup] = useState("")
  const [newPxMin, setNewPxMin] = useState("")
  const [newPxMax, setNewPxMax] = useState("")
  const [newPrice, setNewPrice] = useState("")

  useEffect(() => {
    if (initialValue && Array.isArray(initialValue[0])) {
      setPriceGroups(
        initialValue[0].map(item => ({
          person_type: item.person_type,
          group: item.group_type,
          px_min: item.px_min,
          px_max: item.px_max,
          price: item.price,
        }))
      )
    }
  }, [initialValue])

  const handleAdd = () => {
    if (newPersonType && newGroup && newPxMin && newPxMax && newPrice) {
      const newPriceGroups = [
        ...priceGroups,
        {
          person_type: newPersonType,
          group: newGroup,
          px_min: newPxMin,
          px_max: newPxMax,
          price: newPrice,
        },
      ]
      setPriceGroups(newPriceGroups)
      onChange(newPriceGroups)
      setNewPersonType("")
      setNewGroup("")
      setNewPxMin("")
      setNewPxMax("")
      setNewPrice("")
    }
  }

  const handleRemove = index => {
    const newPriceGroups = [...priceGroups]
    newPriceGroups.splice(index, 1)
    setPriceGroups(newPriceGroups)
    onChange(newPriceGroups)
  }

  const groupedAndSortedPrices = () => {
    const grouped = priceGroups.reduce((acc, item) => {
      if (!acc[item.group]) {
        acc[item.group] = []
      }
      acc[item.group].push(item)
      return acc
    }, {})

    Object.keys(grouped).forEach(group => {
      grouped[group].sort((a, b) => {
        if (a.person_type !== b.person_type) {
          return a.person_type.localeCompare(b.person_type)
        }
        return a.px_min - b.px_min
      })
    })

    return grouped
  }

  const groupedPrices = groupedAndSortedPrices()

  return (
    <div>
      {Object.keys(groupedPrices).map(group => (
        <div key={group}>
          <h6>
            {"("}
            {group}
            {")"}
          </h6>
          <ul>
            {groupedPrices[group].map((item, index) => (
              <li key={index} className="mb-2">
                {item?.person_type} ({item?.group}), {item?.px_min}-
                {item?.px_max} pax, ${item?.price}{" "}
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleRemove(priceGroups.indexOf(item))}
                  style={{ marginLeft: 20 }}
                  className="btn btn-sm btn-soft-danger"
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <Row className="mb-3">
        <Col md={6}>
          <FormGroup>
            <Label for="new-person-type">Person Type</Label>
            <Input
              type="select"
              id="new-person-type"
              value={newPersonType}
              onChange={e => setNewPersonType(e.target.value)}
              placeholder="Select Person Type"
            >
              <option value="">Select Person Type</option>
              {personTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="new-group">Group</Label>
            <Input
              type="select"
              id="new-group"
              value={newGroup}
              onChange={e => setNewGroup(e.target.value)}
              placeholder="Select Group"
            >
              <option value="">Select Group</option>
              {predefinedGroups.map((group, groupIndex) => (
                <option key={groupIndex} value={group}>
                  {group}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="new-px-min">Px Min</Label>
            <Input
              type="number"
              id="new-px-min"
              value={newPxMin}
              onChange={e => setNewPxMin(e.target.value)}
              placeholder="Enter Min Pax"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="new-px-max">Px Max</Label>
            <Input
              type="number"
              id="new-px-max"
              value={newPxMax}
              onChange={e => setNewPxMax(e.target.value)}
              placeholder="Enter Max Pax"
            />
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label for="new-price">Price</Label>
            <Input
              type="number"
              id="new-price"
              value={newPrice}
              onChange={e => setNewPrice(e.target.value)}
              placeholder="Enter Price"
            />
          </FormGroup>
        </Col>
        <Col md={2} className="d-flex align-items-end">
          <Button color="primary" onClick={handleAdd}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default PriceGroupBuilder
