import React, { useEffect, useState } from "react"
import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

const predefinedInclusions = [
  // { id: "1", description: "Air-conditioned vehicle", included: false },
  // { id: "2", description: "Private transportation", included: false },
  // {
  //   id: "3",
  //   description: "Admission fees to Featherdale Wildlife Park",
  //   included: false,
  // },
  // {
  //   id: "4",
  //   description: "Admission to Scenic World Katoomba",
  //   included: false,
  // },
  // { id: "5", description: "Entry to Echo Point Lookout", included: false },
  // { id: "6", description: "Entry to Katoomba Falls Lookout", included: false },
  // {
  //   id: "7",
  //   description: "Pickup from Sydney city hotel/address and around",
  //   included: false,
  // },
  // {
  //   id: "8",
  //   description: "Ferry Ticket return to Circular Quay",
  //   included: false,
  // },
]

const predefinedExclusions = [
  // { id: "1", description: "Lunch", included: false },
]

const InclusionExclusionForm = ({ onChange, data }) => {
  const [inclusions, setInclusions] = useState([])
  const [exclusions, setExclusions] = useState([])
  const [newInclusionText, setNewInclusionText] = useState("")
  const [newExclusionText, setNewExclusionText] = useState("")
  const [showNewInclusionInput, setShowNewInclusionInput] = useState(false)
  const [showNewExclusionInput, setShowNewExclusionInput] = useState(false)

  // console.log(data, inclusions, exclusions)
  useEffect(() => {
    if (data) {
      const incomingInclusions = data.filter(item => item.type === "include")
      const incomingExclusions = data.filter(item => item.type === "exclude")

      const mergeItems = (predefinedItems, incomingItems) => {
        const merged = predefinedItems.map(pre => {
          const found = incomingItems.find(
            item => item.title === pre.description
          )
          return found ? { ...pre, included: true } : pre
        })
        const additionalItems = incomingItems.filter(
          item => !predefinedItems.find(pre => pre.description === item.title)
        )
        return merged.concat(
          additionalItems.map(item => ({
            id: item.id,
            description: item.title,
            included: true,
          }))
        )
      }

      setInclusions(mergeItems(predefinedInclusions, incomingInclusions))
      setExclusions(mergeItems(predefinedExclusions, incomingExclusions))
    } else {
      setInclusions(predefinedInclusions)
      setExclusions(predefinedExclusions)
    }
  }, [data])

  const handleChange = (id, checked, isIncluded) => {
    if (isIncluded == "include") {
      setInclusions(current =>
        current.map(item =>
          item.id === id ? { ...item, included: checked } : item
        )
      )
    } else {
      setExclusions(current =>
        current.map(item =>
          item.id === id ? { ...item, included: checked } : item
        )
      )
    }
  }

  const handleAddNewInclusion = () => {
    if (newInclusionText.trim()) {
      const newId = `inc-${inclusions.length + 1}`
      setInclusions(current => [
        ...current,
        { id: newId, description: newInclusionText, included: true },
      ])
      setNewInclusionText("")
      setShowNewInclusionInput(false)
    }
  }

  const handleAddNewExclusion = () => {
    if (newExclusionText.trim()) {
      const newId = `exc-${exclusions.length + 1}`
      setExclusions(current => [
        ...current,
        { id: newId, description: newExclusionText, included: true },
      ])
      setNewExclusionText("")
      setShowNewExclusionInput(false)
    }
  }

  useEffect(() => {
    onChange({ inclusions, exclusions })
  }, [inclusions, exclusions, onChange])

  return (
    <TabPane tabId="inclusionsExclusions">
      {/* <h2>Inclusions & Exclusions</h2> */}
      <h3>What's included</h3>
      {inclusions.map(item => (
        <FormGroup key={item.id}>
          <Label>
            <Input
              type="checkbox"
              // value="yes"
              checked={item.included === true ? true : false}
              onChange={e => handleChange(item.id, e.target.checked, "include")}
              onClick={e => {
                e.preventDefault()
                if (item.included === false) {
                  handleChange(item.id, (e.target.checked = true), "include")
                  console.log("no")
                } else {
                  handleChange(item.id, (e.target.checked = false), "include")
                  console.log("yes")
                }
              }}
            />{" "}
            {item.description}
          </Label>
        </FormGroup>
      ))}
      {showNewInclusionInput ? (
        <>
          <FormGroup>
            <Label>New Inclusion:</Label>
            <Input
              type="text"
              value={newInclusionText}
              onChange={e => setNewInclusionText(e.target.value)}
              placeholder="Enter a new inclusion"
            />
          </FormGroup>
          <Button color="success" onClick={handleAddNewInclusion}>
            Add
          </Button>
        </>
      ) : (
        <Button color="primary" onClick={() => setShowNewInclusionInput(true)}>
          + Add New Inclusion
        </Button>
      )}
      <h3 style={{ marginTop: 20 }}>What's excluded</h3>
      {exclusions.map(item => (
        <FormGroup key={item.id}>
          <Label>
            <Input
              type="checkbox"
              checked={item.included === true ? true : false}
              onChange={e => handleChange(item.id, e.target.checked, "exclude")}
              onClick={e => {
                e.preventDefault()
                if (item.included === false) {
                  handleChange(item.id, (e.target.checked = true), "exclude")
                  console.log("no")
                } else {
                  handleChange(item.id, (e.target.checked = false), "exclude")
                  console.log("yes")
                }
              }}
            />{" "}
            {item.description}
          </Label>
        </FormGroup>
      ))}
      {showNewExclusionInput ? (
        <>
          <FormGroup>
            <Label>New Exclusion:</Label>
            <Input
              type="text"
              value={newExclusionText}
              onChange={e => setNewExclusionText(e.target.value)}
              placeholder="Enter a new exclusion"
            />
          </FormGroup>
          <Button color="success" onClick={handleAddNewExclusion}>
            Add
          </Button>
        </>
      ) : (
        <Button color="primary" onClick={() => setShowNewExclusionInput(true)}>
          + Add New Exclusion
        </Button>
      )}
    </TabPane>
  )
}

export default InclusionExclusionForm
